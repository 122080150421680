import React, { useState } from "react";
import Graph from "react-graph-vis";
import { Center, useLocalStorage } from './Utils'
import * as _ from 'lodash'

const options = {
  interaction: {
    keyboard: {
        enabled: true,
    },
  },
  autoResize: true,
  height: '100%',
  width: '100%',
    physics: false,
    layout: {
    hierarchical: {
        sortMethod: "directed",
        direction: "LR",
        levelSeparation: 2000,
        edgeMinimization: true,
        nodeSpacing: 1000,
        treeSpacing: 1000,
        blockShifting: true,
        parentCentralization: false,
    }
    },
    edges: {
        smooth: {
          enabled: true,
          type: 'horizontal',
          roundness: .23,
        },
      arrows: {
          from: { enabled: false },
          to: { enabled: false },
      },
      scaling:{
        label: true,
      },
      shadow: false,
      background: {
        enabled: true,
      },
    },

  nodes: {
    shape: 'box',
    margin: 30,
    mass: 20,
    scaling: {
        label: true
      },
      font: { size: 90, align: "inside" },
      widthConstraint: {
        minimum: 200,
        maximum: 2000
      },
      shadow: true
  },
};

  const graph = {
    nodes: [
      { id: 1, label: "Node 1", title: "node 1 tootip text", level: 0, group:1 },
      { id: 2, label: "Node 2", title: "node 2 tootip text", level: 1, group:1 },
      { id: 3, label: "Node 3", title: "node 3 tootip text", level: 1, group:1 },
      { id: 4, label: "Node 4", title: "node 4 tootip text", level: 2, group:1 },
      { id: 5, label: "Node 5", title: "node 5 tootip text", level: 2, group:1 },
      { id: 6, label: "Node 6", title: "node 6 tootip text", level: 1, group:2 },
    ],
    edges: [
      { from: 1, to: 2 },
      { from: 1, to: 3 },
      { from: 2, to: 4 },
      { from: 2, to: 5 },
      { from: 1, to: 6 }
    ]
  };

export default function App() {
  const [loaded, setLoaded] = useState(0)
  const [data, setData] = useLocalStorage('animomap_data', graph)
  const [network, setNetwork] = useState()

  const events = {
    select: function(event) {
      const { nodes, edges } = event;
      console.log(nodes, edges)
      network.focus(String(nodes[0]))
    },
    doubleClick: function(event) {
      const { nodes: doubleNodes, edges: doubleEdges } = event;
      console.log(event.nodes)
      console.log(doubleNodes)
      if (!doubleNodes || doubleNodes.length === 0){
        return
      }
      
      const { nodes, edges } = data
      console.log(event)
      const full_node = _.filter(nodes, node => {
        return node.id == doubleNodes[0]
      })[0]
      const cloned = _.cloneDeep(full_node)
      cloned['id'] = Number(_.uniqueId(Number(Math.random() * 10000)))
      cloned['level'] = full_node['level'] + 1
      cloned['label'] = full_node['label'] + ' child'
      const egde = {'from': full_node['id'], 'to': cloned['id']}
      
      edges.push(egde);
      nodes.push(cloned);

      network.setData({nodes, edges})
      // setData
      network.focus(String(cloned.id))
      network.selectNodes([cloned.id])
      setData({nodes, edges})
    }
  }

  return (
    <div 
      style={{
        overflowY: 'hidden',
        overflowX: 'hidden',
        height: "1000px",
        width: "100%"
      }}
    >
      <Graph
        graph={data}
        options={options}
        events={events}
        getNetwork={network => {
          setNetwork(network)
        }}
      />
    </div>
  );
}