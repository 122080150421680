import React, { useState } from 'react'
import { SplitColorChannelText } from "react-text-fun";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Row, Col } from 'react-bootstrap'

function ErrorNotFound(props) {
	const location = useLocation()
  const [value, setValue] = useState(0);
  const [textValue, setTextValue] = useState(location.pathname)

  const updateShader = e => setValue(e.clientX);

  return (
    <Row onMouseMove={updateShader}>
    	<Col>
    		<h1>404</h1>
    		<h2>Not Found</h2>
	      <SplitColorChannelText
	        text={textValue}
	        fontSize={50}
	        rotation={0.8}
	        rgbOffset={value === 0 ? 0.095 : value / 10000}
	        addBlur={false}
	        addNoise={true}
	      /> is not found...
	     </Col>
    </Row>
  );

}

export { ErrorNotFound,  }