import { DragPreviewImage, useDrag } from 'react-dnd';
import { Types } from './ItemTypes';
import { CenterP } from '../Utils'
import { Row, Col } from 'react-bootstrap'
import * as _ from 'lodash'

const BasePiece = (props) => {
  const extraStyle = {
    fontSize: '4em',
    fontWeight: 'bold',
    cursor: 'move',
    // color: 'blue',
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: props.type.name,
    item: { id: props.id, type_: props.type, icon: props.icon, team: props.team },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), []);
  console.log('piece ', props.id, ' ', props.type.name, ' isDragging: ', isDragging)
  return (
    <>
      {/*{<DragPreviewImage connect={preview}/>}*/}
      <Row ref={drag} style={{
        ...extraStyle,
        color: props.team,
        height: '100%',
        opacity: isDragging ? 0.5 : 1,
      }}>
        <CenterP what={props.icon} />
      </Row>
    </>
  );
};

export const Piece = (props) => {
  const id = props.id || _.uniqueId()
  return <BasePiece id={id} type={props.type} icon={props.icon} team={props.team}/>
};

export const Knight = (props) => {
  const id = props.id || _.uniqueId()
  return <Piece id={id} type={Types.KNIGHT} icon={"♘"} team={props.team} /> 
}

export const Queen = (props) => {
  const id = props.id || _.uniqueId()
  return <Piece id={id} type={Types.QUEEN} icon={"♕"} team={props.team} /> 
}
