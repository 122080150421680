
import React from 'react';
import { Text, View } from 'react-native';
// import BootstrapStyleSheet from 'react-native-bootstrap-styles';

// const bootstrapStyleSheet = new BootstrapStyleSheet();
// const { s, c } = bootstrapStyleSheet;


// const WelcomeScreen = () => (
  
//     <View style={[s.body]}>
//       <Text style={[s.text, s.textPrimary]}>Step One</Text>
//       <Text rstyle={[s.text, s.textSecondary]}>
//         Edit App.js to change this screen and turn it
//         into your app.
//       </Text>
//       <Text style={[s.text, s.textPrimary]}>See Your Changes</Text>
//       <Text>
//         Press Cmd + R inside the simulator to reload
//         your app’s code.
//       </Text>
//       <Text style={[s.text, s.textPrimary]}>Debug</Text>
//       <Text>
//         Press Cmd + M or Shake your device to open the
//         React Native Debug Menu.
//       </Text>
//       <Text style={[s.text, s.textPrimary]}>Learn</Text>
//       <Text>
//         Read the docs to discover what to do next:
//       </Text>
//      </View>

// );

export default {}