import React, { useState, useEffect } from 'react'
import { useSpring, useTrail, animated,  useSprings, Spring } from 'react-spring'
import { Container, Row, Col, Navbar} from 'react-bootstrap'
import { useDrag } from 'react-use-gesture'
import { MarioSprite, MarioSpriteG1, MarioSpriteG2, MarioSpriteG3 } from './Mario'
import clamp from 'lodash-es/clamp'
import { useGesture } from 'react-with-gesture'
import { Center } from './Utils'
import Triangle from './Triangle'
import Triangle2 from './Triangle2'
import { SVGSource, SVG } from './newmario'
import { DistortionText } from 'react-text-fun'
import { SplitColorChannelText } from "react-text-fun";



function Numbers() {
  const [active, setActive] = useState(false);
  const { x, setX, stop} = useSpring({ config: { duration: 800 }, x: active ? 1 : 0 });

  useEffect(() => {
    const id = setTimeout(() => {
      setActive(!active);
    }, 2000);

    return () => clearTimeout(id);
  }, [active]);

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <div className="Numbers">
      <svg
        viewBox="0 0 210 297"
        height="250px"
        width="250px"
        onClick={() => stop()}
      >
        <animated.path
          d={x.to({
            range: [0, 1],
            output: [
              "m 127.04381,211.10935 c 0,-14.91359 0.003,-32.17749 0.003,-42.36834 -0.004,-20.38227 -0.003,-21.7271 -0.003,-42.10937 0,-9.01496 0.003,-25.3606 0.003,-37.8159 -10.12444,0 -16.33391,-0.001 -24.82899,-0.001 -10.247197,3.062792 -11.04603,3.338535 -23.70081,7.71398 3.219216,10.98961 3.923509,12.69764 7.027253,24.85177 6.190354,-2.25005 6.308442,-2.18734 11.865083,-3.67743 0,10.12296 0.0028,17.26578 0.0028,22.75715 0,2.74568 -6.06e-4,15.5244 -0.0013,22.72161 -6.74e-4,7.19722 -0.0014,18.91094 -0.0017,24.27864 -5.47e-4,10.73539 0.003,23.6491 0.003,23.6491 10.54953,0 22.21003,-2.1e-4 29.63216,-2.1e-4 z",
              "m 147.37298,211.0425 c 0,-12.87559 -0.0733,-16.96041 -0.0733,-25.94198 -13.28238,0 -30.99957,-0.006 -48.083266,-0.006 1.945436,-7.2604 10.848226,-12.9841 25.592646,-25.65209 14.56441,-12.51334 21.31788,-18.67489 21.31788,-38.38628 0,-16.57099 -15.69897,-34.99446 -40.24609,-34.99446 -23.223349,0 -39.737742,15.0486 -43.25384,31.78353 8.205794,2.62262 18.220113,5.4265 25.810648,7.67225 3.235642,-9.6238 10.773686,-12.89988 16.734722,-11.97323 5.792,0.90037 10.5743,4.53094 10.5743,11.16538 0,9.71751 -10.84859,17.07429 -25.914468,31.69552 -26.242909,25.46838 -26.270987,31.22153 -26.270987,54.5227 33.135324,0 58.393495,0.11466 83.811755,0.11466 z",
            ],
          })}
        />
      </svg>
    </div>
  );
}


function MovingMarioSprint() {
	const [active, setActive] = useState(true);
	const { x } = useSpring({ config: { duration: 800 }, x: active ? 1 : 0 });

	useEffect(() => {
	  const id = setTimeout(() => {
	    setActive(!active);
	  }, 2000);
	  return () => clearTimeout(id);
	}, [active]);

	useEffect(() => {
	  setActive(true);
	}, []);

	const range = [0, 1]
	const output = [<MarioSpriteG1 />, <MarioSpriteG2 />]
	console.log(x)
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 16 32.5" height="250px" width="250px">
			<animated.g>
				{x.to({
					range: range,
					output: output
				})}
			</animated.g>
		</svg>
	)
}


function Blotter(props) {

  const [value, setValue] = React.useState(0);

  const updateShader = e => setValue(e.clientX);

  return (
    <div className="App" onMouseMove={updateShader}>
      <SplitColorChannelText
        text={"React Text Fun"}
        fontSize={130}
        rotation={0.8}
        rgbOffset={value === 0 ? 0.095 : value / 10000}
        addBlur={false}
        addNoise={true}
      />
    </div>
  );

}

function Slide() {
   const ref = React.createRef()
   return (
   	<Col>
   	  <Row>
      	{Center(Numbers)}
      </Row>
      <Row>
      	<Col>
	      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 16 32.5" height="250px" width="250px">
	      	<MarioSpriteG1 />
	      </svg>
	    </Col>
	    <Col>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 16 32.5" height="250px" width="250px">
				<MarioSpriteG2 />
			</svg>
		</Col>
		<Col>
			<Triangle />
		</Col>
		<Col>
			<Triangle2 />
		</Col>
	  </Row>
	  <Row>
	  	<SVG id='mario' />
	  </Row>
	  <Row>
	  	<Blotter />
	  </Row>
	</Col>
   ); 
}

export default Slide;