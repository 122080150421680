import React, { useState, useEffect } from 'react'
import { useSpring, useTrail, animated,  useSprings, Spring } from 'react-spring'
import { Container, Row, Col, Navbar, Nav} from 'react-bootstrap'
import { useDrag } from 'react-use-gesture'
import './Spring.css'
import './App.css'
import LogoSVG from './logo.svg'
import clamp from 'lodash-es/clamp'
import { useGesture } from 'react-with-gesture'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { useLocalStorage, Center, CenterP } from './Utils'
import Sample from './Sample'
import Slide from './Slide'
import Rocket from './Rocket'
import ImageScroll from './ImageScroll'
import Faces from './Faces'
import Pdf from './Pdf'
import Pdfs from './Pdfs'
import Shapes from './Shapes'
import Areas from './Area'
import Stack from './Stack'
import Dims from './Dims'
import Tree from './Tree'
import Apps from './Apps'
import PullLogoPieces from './PullLogo'
import { ErrorNotFound } from './Errors'

const routes = [
  {
    path: "/",
    exact: true,
    main: PullLogoPieces,
    name: 'Home'
  },
  {
    path: "/apps",
    main: Apps,
    name: 'Apps'
  },
];


const noHighlight = {
    'KhtmlUserSelect': 'none',
    'OUserSelect': 'none',
    'MozUserSelect': 'none',
    'WebkitUserSelect': 'none',
    'userSelect': 'none',
}

function PullLogo() {
  const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }))
  const bind = useGesture(({ down, delta, velocity }) => {
    velocity = clamp(velocity, 1, 8)
    set({ xy: down ? delta : [0, 0], config: { mass: velocity, tension: 500 * velocity, friction: 50 } })
  })
  return <animated.div {...bind()} style={{ ...noHighlight, transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }} >
          <img
            alt=""
            src={LogoSVG}
            width="30"
            height="30"
            className="d-inline-block align-top"
            style={{ ...noHighlight }}
          />
          {'  '}
        Orange Quarry</animated.div>
}


function App() {
  const sizeRef = React.useRef();
   return (
    <Router >
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand>
          <PullLogo />
        </Navbar.Brand>
        <Nav variant="pills" style={{ overflow: 'auto' }} >
          {
            routes.map((route, index) => {
              return (
                <Nav.Item key={index}>
                  <Nav.Link as={Link} to={route.path}>{route.name}</Nav.Link>
                </Nav.Item>
              )
            })
          }
        </Nav>
      </Navbar>
     <Container >
       <Row >
         <Col className='boarder' ref={sizeRef} style={{ overflow: "scroll", 'width': "100%", height: '92vh', 'backgroundColor': '#f7edbc'}} >
            {
              // to do refactor the below into a function 
            }
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => (
                      <route.main {...props} width={'90hh'} height={props.height} sizeRef={sizeRef} />
                  )}
                />
              ))}
              <Route render={(p) => (
                  <ErrorNotFound {...p} />
                )} />
            </Switch>
          </Col>
        </Row>
     </Container>
    </Router>
   ); 
}

export default App;