
import React, { useState, useEffect } from 'react'
import { useSpring, useTrail, animated,  useSprings, Spring } from 'react-spring'
import { interpolate } from 'flubber'

const TRIANGLE = 'M20,380 L380,380 L380,380 L200,20 L20,380 Z'
const RECTANGLE = 'M20,20 L20,380 L380,380 L380,20 L20,20 Z'
const styles = {
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    willChange: 'background',
  },
  shape: { width: 300, height: 300, willChange: 'transform' },
}


function makeInterpolator(x, current) {
  if ((Math.floor(x.get()) + 1) % 2) {
    return interpolate(current, RECTANGLE)
  } else {
    return interpolate(current, TRIANGLE)
  }
}

function floor(x){
  return x.get() - Math.floor(x.get())
}

export default function Triangle() {
  
  const [toggle, setToggle] = useState(0);
  const [svgState, setSvgState] = useState(TRIANGLE);

  const [{x}, set] = useSpring(() => ({
    x: 0,     
    config: { mass: 10, tension: 50, friction: 10 },
    onStart: () => set({x: 0.1}),
    onRest: () => set({x: 0}),
    // onFrame: () => set({x: x.get() + 0.1})
  }))
  set({x: x.get() + 0.1})

  const interpolator = makeInterpolator(x, svgState)
  const path = interpolator(floor(x))

  useEffect(() => {
    const id = setTimeout(() => {
      setToggle(!toggle);
      setSvgState(path)
    }, 20);
    return () => clearTimeout(id);
  }, [toggle]);
    

  return (
      <svg viewBox="0 0 600 600">
        <animated.g style={{x}}>
          <animated.path id="path-1" d={path} />
        </animated.g>
      </svg>
  )
}
