import React, { useState, useEffect } from 'react'


function MarioSprite() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="160" viewBox="0 -0.5 16 32.5" >
          <defs>
              <path id="pt-1-2" stroke="#ffe3ab" d="M7 1h5M5 2h6M4 3h6M4 4h11M5 12h3M4 13h1M8 13h1M4 14h1M9 14h1M4 15h1M10 15h2M3 16h2M3 17h3M3 18h3M3 19h4M3 20h6M12 20h1M3 21h10M3 22h9M4 23h7M12 23h2M4 24h5M11 24h3M4 25h4M9 25h4M4 26h3M9 26h4"/>
              <path id="pt-2-2" stroke="#ffa347" d="M11 2h1M10 3h2M7 5h2M10 5h3M4 6h2M7 6h2M11 6h4M4 7h2M8 7h8M4 8h2M8 8h3M12 8h4M4 9h5M5 10h5M6 11h8M8 12h3M12 15h2M12 16h4M12 17h4M13 18h3M13 19h3"/>
              <path id="pt-3-2" stroke="#f83800" d="M4 5h3M9 5h1M3 6h1M6 6h1M9 6h2M3 7h1M6 7h2M2 8h2M6 8h2M11 8h1M2 9h2M9 9h6M3 10h2M10 10h5M5 11h1M4 12h1M3 13h1M5 13h3M9 13h1M3 14h1M5 14h4M10 14h1M2 15h2M5 15h5M2 16h1M5 16h7M2 17h1M6 17h6M6 18h7M7 19h6M9 20h3M12 22h1M0 23h4M11 23h1M0 24h4M9 24h2M0 25h4M8 25h1M0 26h4M0 27h4M9 27h4M0 28h2M9 28h4M0 29h1M9 29h6M9 30h6"/>

              <path id="pt-1-3" stroke="#ffe3ab" d="M6 1h5M4 2h6M3 3h6M3 4h11M4 12h4M4 13h1M7 13h2M3 14h1M8 14h2M3 15h1M8 15h2M3 16h1M8 16h3M3 17h1M3 18h1M2 19h2M12 19h1M2 20h3M12 20h2M2 21h4M11 21h3M2 22h6M10 22h4M3 23h5M11 23h2M4 24h3M4 25h2M5 26h2M6 27h3" />
              <path id="pt-2-3" stroke="#ffa347" d="M10 2h1M9 3h2M6 5h2M9 5h3M3 6h2M6 6h2M10 6h4M3 7h2M7 7h8M3 8h2M7 8h3M11 8h4M3 9h5M4 10h5M5 11h8M8 12h2M9 17h2M8 18h4M8 19h4M8 20h4M8 21h3" />
              <path id="pt-3-3" stroke="#f83800" d="M3 5h3M8 5h1M2 6h1M5 6h1M8 6h2M2 7h1M5 7h2M1 8h2M5 8h2M10 8h1M1 9h2M8 9h6M2 10h2M9 10h5M3 11h2M3 13h1M5 13h2M9 13h2M2 14h1M4 14h4M10 14h1M2 15h1M4 15h4M10 15h2M2 16h1M4 16h4M11 16h1M2 17h1M4 17h5M11 17h1M2 18h1M4 18h4M4 19h4M5 20h3M6 21h2M8 22h2M8 23h3M7 24h6M6 25h6M7 26h4M5 27h1M9 27h3M5 28h4M10 28h2M4 29h5M4 30h7M6 31h5" />
            
          </defs>
          <g className="frames">
            <path id="pt-1-1" stroke="#ffe3ab" d="M6 0h5M4 1h6M3 2h6M3 3h11M4 12h4M10 12h1M7 13h2M11 13h1M8 14h2M11 14h1M8 15h2M12 15h1M8 16h2M12 16h1M7 17h3M12 17h1M6 18h3M10 18h1M4 19h9M5 20h8M5 21h8M4 22h9M4 23h7M3 24h1M5 24h6M3 25h2M6 25h5M3 26h3M8 26h3M4 27h4M5 28h1" />
            <path id="pt-2-1" stroke="#ffa347" d="M10 1h1M9 2h2M6 4h2M9 4h3M3 5h2M6 5h2M10 5h4M3 6h2M7 6h8M3 7h2M7 7h3M11 7h4M3 8h5M4 9h5M5 10h8M8 11h2M14 12h1M13 13h3M13 14h3M13 15h3M14 16h1M9 18h1M12 18h1M0 20h5M0 21h5M0 22h4M1 23h3" />
            <path id="pt-3-1" stroke="#f83800" d="M3 4h3M8 4h1M2 5h1M5 5h1M8 5h2M2 6h1M5 6h2M1 7h2M5 7h2M10 7h1M1 8h2M8 8h6M1 9h3M9 9h5M3 10h2M5 11h3M8 12h2M3 13h4M9 13h2M2 14h6M10 14h1M12 14h1M1 15h7M10 15h2M1 16h7M10 16h2M13 16h1M15 16h1M1 17h6M10 17h2M13 17h2M0 18h6M11 18h1M13 18h1M0 19h4M15 21h1M14 22h2M11 23h5M4 24h1M11 24h5M2 25h1M5 25h1M11 25h5M0 26h3M6 26h2M11 26h5M0 27h4M11 27h5M0 28h5M1 29h3M1 30h3M2 31h3" />
          </g>
        </svg>
    );
}


function MarioSpriteG1() {
  return (
    <g className="frames">
      <path id="pt-1-1" stroke="#ffe3ab" d="M6 0h5M4 1h6M3 2h6M3 3h11M4 12h4M10 12h1M7 13h2M11 13h1M8 14h2M11 14h1M8 15h2M12 15h1M8 16h2M12 16h1M7 17h3M12 17h1M6 18h3M10 18h1M4 19h9M5 20h8M5 21h8M4 22h9M4 23h7M3 24h1M5 24h6M3 25h2M6 25h5M3 26h3M8 26h3M4 27h4M5 28h1" />
      <path id="pt-2-1" stroke="#ffa347" d="M10 1h1M9 2h2M6 4h2M9 4h3M3 5h2M6 5h2M10 5h4M3 6h2M7 6h8M3 7h2M7 7h3M11 7h4M3 8h5M4 9h5M5 10h8M8 11h2M14 12h1M13 13h3M13 14h3M13 15h3M14 16h1M9 18h1M12 18h1M0 20h5M0 21h5M0 22h4M1 23h3" />
      <path id="pt-3-1" stroke="#f83800" d="M3 4h3M8 4h1M2 5h1M5 5h1M8 5h2M2 6h1M5 6h2M1 7h2M5 7h2M10 7h1M1 8h2M8 8h6M1 9h3M9 9h5M3 10h2M5 11h3M8 12h2M3 13h4M9 13h2M2 14h6M10 14h1M12 14h1M1 15h7M10 15h2M1 16h7M10 16h2M13 16h1M15 16h1M1 17h6M10 17h2M13 17h2M0 18h6M11 18h1M13 18h1M0 19h4M15 21h1M14 22h2M11 23h5M4 24h1M11 24h5M2 25h1M5 25h1M11 25h5M0 26h3M6 26h2M11 26h5M0 27h4M11 27h5M0 28h5M1 29h3M1 30h3M2 31h3" />
    </g>
  )
}

function MarioSpriteG2() {
  return (
    <g className="frames">
      <path id="pt-1-2" stroke="#ffe3ab" d="M7 1h5M5 2h6M4 3h6M4 4h11M5 12h3M4 13h1M8 13h1M4 14h1M9 14h1M4 15h1M10 15h2M3 16h2M3 17h3M3 18h3M3 19h4M3 20h6M12 20h1M3 21h10M3 22h9M4 23h7M12 23h2M4 24h5M11 24h3M4 25h4M9 25h4M4 26h3M9 26h4"/>
      <path id="pt-2-2" stroke="#ffa347" d="M11 2h1M10 3h2M7 5h2M10 5h3M4 6h2M7 6h2M11 6h4M4 7h2M8 7h8M4 8h2M8 8h3M12 8h4M4 9h5M5 10h5M6 11h8M8 12h3M12 15h2M12 16h4M12 17h4M13 18h3M13 19h3"/>
      <path id="pt-3-2" stroke="#f83800" d="M4 5h3M9 5h1M3 6h1M6 6h1M9 6h2M3 7h1M6 7h2M2 8h2M6 8h2M11 8h1M2 9h2M9 9h6M3 10h2M10 10h5M5 11h1M4 12h1M3 13h1M5 13h3M9 13h1M3 14h1M5 14h4M10 14h1M2 15h2M5 15h5M2 16h1M5 16h7M2 17h1M6 17h6M6 18h7M7 19h6M9 20h3M12 22h1M0 23h4M11 23h1M0 24h4M9 24h2M0 25h4M8 25h1M0 26h4M0 27h4M9 27h4M0 28h2M9 28h4M0 29h1M9 29h6M9 30h6"/>
    </g>
  )
}

function MarioSpriteG3() {
  return (
    <g className="frames">
      <path id="pt-1-3" stroke="#ffe3ab" d="M6 1h5M4 2h6M3 3h6M3 4h11M4 12h4M4 13h1M7 13h2M3 14h1M8 14h2M3 15h1M8 15h2M3 16h1M8 16h3M3 17h1M3 18h1M2 19h2M12 19h1M2 20h3M12 20h2M2 21h4M11 21h3M2 22h6M10 22h4M3 23h5M11 23h2M4 24h3M4 25h2M5 26h2M6 27h3" />
      <path id="pt-2-3" stroke="#ffa347" d="M10 2h1M9 3h2M6 5h2M9 5h3M3 6h2M6 6h2M10 6h4M3 7h2M7 7h8M3 8h2M7 8h3M11 8h4M3 9h5M4 10h5M5 11h8M8 12h2M9 17h2M8 18h4M8 19h4M8 20h4M8 21h3" />
      <path id="pt-3-3" stroke="#f83800" d="M3 5h3M8 5h1M2 6h1M5 6h1M8 6h2M2 7h1M5 7h2M1 8h2M5 8h2M10 8h1M1 9h2M8 9h6M2 10h2M9 10h5M3 11h2M3 13h1M5 13h2M9 13h2M2 14h1M4 14h4M10 14h1M2 15h1M4 15h4M10 15h2M2 16h1M4 16h4M11 16h1M2 17h1M4 17h5M11 17h1M2 18h1M4 18h4M4 19h4M5 20h3M6 21h2M8 22h2M8 23h3M7 24h6M6 25h6M7 26h4M5 27h1M9 27h3M5 28h4M10 28h2M4 29h5M4 30h7M6 31h5" />
    </g>
  )
}

export { MarioSprite, MarioSpriteG1, MarioSpriteG2, MarioSpriteG3 };