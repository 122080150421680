import React, {useState, useEffect} from 'react'
import { curveBasis } from '@visx/curve'
import { AreaClosed } from '@visx/shape'
import { scaleTime, scaleLinear } from '@visx/scale'
import { ParentSize } from '@visx/responsive'
import { GradientPurpleTeal } from '@visx/gradient'
import { extent, max } from 'd3-array'
import { Spring } from 'react-spring'
import './Area.css'
import { Row, Col } from 'react-bootstrap'
import { letterFrequency } from '@visx/mock-data';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { scaleBand } from '@visx/scale';

// We'll use some mock data from `@visx/mock-data` for this.
const data = letterFrequency;

// Define the graph dimensions and margins
const width = 500;
const height = 500;
const margin = { top: 20, bottom: 20, left: 20, right: 20 };

// Then we'll create some bounds
const xMax = width - margin.left - margin.right;
const yMax = height - margin.top - margin.bottom;

// We'll make some helpers to get at the data we want
const x = d => d.letter;
const y = d => +d.frequency * 100;

// And then scale the graph by our data
const xScale = scaleBand({
  range: [0, xMax],
  round: true,
  domain: data.map(x),
  padding: 0.4,
});
const yScale = scaleLinear({
  range: [yMax, 0],
  round: true,
  domain: [0, Math.max(...data.map(y))],
});

// Compose together the scale and accessor functions to get point functions
const compose = (scale, accessor) => data => scale(accessor(data));
const xPoint = compose(xScale, x);
const yPoint = compose(yScale, y);

// Finally we'll embed it all in an SVG
export default function BarGraph(props) {
  return (
    <svg width={width} height={height}>
    	<GradientPurpleTeal id="gradient" />
      {data.map((d, i) => {
        const barHeight = yMax - yPoint(d);
        return (
          <Group key={`bar-${i}`}>
            <Bar
              x={xPoint(d)}
              y={yMax - barHeight}
              height={barHeight}
              width={xScale.bandwidth()}
              fill={'url(#gradient)'}
            />
          </Group>
        );
      })}
    </svg>
  );
}


// const leData = [
//   { date: new Date('2018-04-08T12:33:40.624Z'), value: 2110 },
//   { date: new Date('2018-04-08T11:33:40.624Z'), value: 1156 },
//   { date: new Date('2018-04-08T10:33:40.624Z'), value: 715 },
//   { date: new Date('2018-04-08T09:33:40.624Z'), value: 2797 },
//   { date: new Date('2018-04-08T08:33:40.624Z'), value: 2361 },
//   { date: new Date('2018-04-08T07:33:40.624Z'), value: 731 },
//   { date: new Date('2018-04-08T06:33:40.624Z'), value: 908 },
//   { date: new Date('2018-04-08T05:33:40.624Z'), value: 691 },
//   { date: new Date('2018-04-08T04:33:40.624Z'), value: 1408 },
//   { date: new Date('2018-04-08T03:33:40.624Z'), value: 1748 },
//   { date: new Date('2018-04-08T02:33:40.624Z'), value: 2834 },
//   { date: new Date('2018-04-08T01:33:40.624Z'), value: 310 },
//   { date: new Date('2018-04-08T00:33:40.624Z'), value: 2877 },
//   { date: new Date('2018-04-07T23:33:40.624Z'), value: 2792 },
//   { date: new Date('2018-04-07T22:33:40.624Z'), value: 250 },
//   { date: new Date('2018-04-07T21:33:40.624Z'), value: 1602 },
//   { date: new Date('2018-04-07T20:33:40.624Z'), value: 1871 },
//   { date: new Date('2018-04-07T19:33:40.624Z'), value: 250 },
//   { date: new Date('2018-04-07T18:33:40.624Z'), value: 2897 },
//   { date: new Date('2018-04-07T17:33:40.624Z'), value: 1902 },
// ]


// const x = d => d.date
// const y = d => d.value

// function App() {
// 	const [toggle, setToggle] = useState(false)


// 	return (
// 		<Row style={{ height: "100vh" }}>
// 		  <ParentSize>
// 		    {({ width, height }) => {
// 		      const xScale = scaleTime({
// 		        range: [0, width],
// 		        domain: extent(data, x),
// 		      })
// 		      const yMax = max(data, y)
// 		      const yScale = scaleLinear({
// 		        range: [height / 2, 0],
// 		        domain: [0, yMax],
// 		        nice: true,
// 		      })
// 		      const interpolate = data.map(d => Math.random() * yMax)
// 		      return (
// 		        <div
// 		          style={{
// 		            width: '100%',
// 		            height: '100%',
// 		            cursor: 'pointer',
// 		            background: '#FF1C68',
// 		          }}
// 		          onClick={() => (setToggle(!toggle))}
// 		          >
// 		          <svg
// 		            style={{ position: 'absolute', bottom: 0 }}
// 		            width={width}
// 		            height={height / 2}
// 		            // viewBox="0 0 200 300"
// 		            >
// 		            <GradientPurpleTeal id="gradient" />
// 		            <g>
// 		              <Spring to={{ interpolate }}>
// 		                {props => (
// 		                  <AreaClosed
// 		                    // data={data.map((d, i) => ({
// 		                    //   date: d.date,
// 		                    //   value: props.interpolate[i],
// 		                    // }))}
// 		                    data={data}
// 		                    xScale={xScale}
// 		                    yScale={yScale}
// 		                    x={x}
// 		                    y={y}
// 		                    strokeWidth={2}
// 		                    stroke={'url(#gradient)'}
// 		                    fill={'url(#gradient)'}
// 		                    curve={curveBasis}
// 		                  />
// 		                )}
// 		              </Spring>
// 		            </g>
// 		          </svg>
// 		        </div>
// 		      )
// 		    }}
// 		  </ParentSize>
// 		 </Row>
// 	)
// }