import React, { useState, useEffect } from 'react'
import { useSpring, useTrail, animated,  useSprings, Spring } from 'react-spring'
import { Container, Row, Col, Navbar} from 'react-bootstrap'
import { useDrag } from 'react-use-gesture'
import { MarioSprite } from './Mario'
import clamp from 'lodash-es/clamp'
import { useGesture } from 'react-with-gesture'
import { Center, useLocalStorage, useWindowDimensions } from './Utils'


function Pull() {
  const [init, setInit] = useLocalStorage('init', [])

  const [{ xy }, set, stop] = useSpring(() => ({ xy: init }))
  const bind = useGesture(({ down, delta, velocity }) => {
    velocity = clamp(velocity, 1, 8)
    set({ xy: down ? delta : [0, 0], config: { mass: velocity, tension: 500 * velocity, friction: 50 } })
    // set({ xy: delta, config: { mass: velocity, tension: 500 * velocity, friction: 50 } })
    setInit(delta)
  })
  return <animated.div {...bind()} style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }} > <MarioSprite /></animated.div>
}



function Sample() {
   return (
      Center(Pull)
   ); 
}

export default Sample;