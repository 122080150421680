import React from "react";

// Create a Context
const NumberContext = React.createContext();
// It returns an object with 2 values:
// { Provider, Consumer }

export default function App(props) {
  // Use the Provider to make a value available to all
  // children and grandchildren
  return (
    <div>
      <NumberContext.Provider value={42}>
        <div>
          <Display />
        </div>
      </NumberContext.Provider>
    </div>
  );
}

function Display() {
  // Use the Consumer to grab the value from context
  // Notice this component didn't get any props!
  const value = React.useContext(NumberContext)
  return (
    <div>The answer is {value}.</div>
  );
}
