import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { useSpring, useTrail, animated,  useSprings, Spring, interpolate } from 'react-spring'
import { Container, Row, Col, Form, InputGroup, Navbar} from 'react-bootstrap'
import { useDrag, useGesture} from 'react-use-gesture'
import clamp from 'lodash-es/clamp'
// import { useGesture } from 'react-with-gesture'
import { useLocalStorage, ColOf, RowOf, Center, CenterP } from './Utils'
import * as _ from 'lodash'


const to = i => ({ x: 0, y: i, scale: 1, rot: -5 + Math.random() * 10, delay: i * 5 })
const from = i => ({ x: 0, rot: 0, scale: 1, y: 0 })
const trans = (r, s) => `perspective(3500px) rotateX(30deg) rotateY(${r / 90}deg) rotateZ(${r}deg) scale(${s})`


function PullPDFStack(props) {
  const [springs, setSprings] = useSprings(props.pages.length, i => ({ ...to(i), from: from(i) })) 
  return (
    <Document renderMode={"svg"} file={props.fileName} onLoadSuccess={props.onDocumentLoadSuccess} >
      {
        springs.map(({ xx, yy, rot, scale }, i) => {
          const pageToShow = props.pages[i]
          console.log(pageToShow)
          console.log(props.readPages)
          if (typeof props.readpages === 'Array' && props.readPages.includes(pageToShow)) {
            return
          }

          return (
            <animated.div key={i} style={{ transform: interpolate([rot, scale], trans) }} >
              <PullPDFPage i={pageToShow} readPages={props.readPages} setReadPages={props.setReadPages}/>
            </animated.div>
          )
        })
      }
    </Document>
  )
}

function PullPDFPage(props) {
  const [x, setX] = useState(props.xint);
  const [y, setY] = useState(props.yint);
  const [{ xy }, set] = useSpring(() => ({ xy: [x, y] }))
  const [pageScale, setPageScale] = useState(1.2);

  const bind = useGesture({
    onDrag: ({ down, offset: [x, y] }) => {
      console.log(x, y)
      if (x < -500) {
        console.log(props.setReadPages)

        try {
          const newReadPages = _.cloneDeep(props.readPages)
          newReadPages.push(props.i)
          props.setReadPages(newReadPages)
        } catch(err) {
          console.log(err)
        }
      }
      set({ xy: [x, y] })
    },
    onWheel: ({ event, ...sharedState }) => {
      event.persist()
      setPageScale(clamp([Math.abs(event.deltaY)]), 0, 4)
    }
  })

  return (
    <animated.div {...bind(props.i)} style={{ position: 'absolute', transform: xy.to((x, y) => `translate3d(${x}px,${y}px,0)`) }} >
      <Page scale={pageScale} renderMode={"svg"} pageNumber={props.i} />
    </animated.div>
  )

}


function MyApp() {
  console.log('Running MyApp')
  const [firstPage, setFirstPage] = useState(1)
  const [numPages, setNumPages] = useState(firstPage);
  const [pageNumber, setPageNumber] = useLocalStorage('pageNumber', 1);
  const [fileName, setFileName] = useLocalStorage('file', 'test.pdf');
  const [xint, setXint] = useLocalStorage('xint', -500);
  const [yint, setYint] = useLocalStorage('yint', 500);

  const [readPages, setReadPages] = useLocalStorage('readPages', []);
  console.log(readPages, setReadPages)
  const pages = _.range(numPages).map((i) => {
    return i + 1
  })
  const reversedPages = _.reverse(_.remove(pages, p => {
    return !readPages.includes(p)
  }))

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <Row>
      <Col lg="12">
        <RowOf what={<h1>PDF Viewer</h1>} />
        <RowOf what={<h3>{fileName}</h3>} />
        <RowOf what={<div style={{ height: "3vh" }}></div>} />
        <RowOf what={
          <ColOf what={
            <PullPDFStack 
              xint={xint} 
              yint={yint} 
              onDocumentLoadSuccess={onDocumentLoadSuccess} 
              fileName={fileName}
              numPages={numPages}
              firstPage={firstPage}
              setFirstPage={setFirstPage}
              pages={reversedPages}
              readPages={readPages}
              setReadPages={setReadPages}
            />
          }/>
        }/>
      </Col>
    </Row>
  );
}


export default MyApp