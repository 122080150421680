export const Types = {
  ACTIVE: 'active',
  KNIGHT: {
  	'name': 'knight',
  	'moveFunc': (dx, dy) => {
  		return (
  		  (Math.abs(dx) === 2 && Math.abs(dy) === 1) ||
  		  (Math.abs(dx) === 1 && Math.abs(dy) === 2)
  		)
  	},
  },
  KING: {
  	'name': 'king',
  	'moveFunc': (dx, dy) => {
  		return (
  		  (Math.abs(dx) === 1 && Math.abs(dy) === 0) || 
  		  (Math.abs(dx) === 1 && Math.abs(dy) === 1) ||
  		  (Math.abs(dx) === 0 && Math.abs(dy) === 1)
  		)
  	}
  },
  QUEEN: {
  	'name': 'queen',
  	'moveFunc': (dx, dy) => {
  		return (
  			(Math.abs(dx) === 0) ||
  			(Math.abs(dy) === 0) ||
  			(Math.abs(dx) === Math.abs(dy))
  		)
  	}
  },
}
