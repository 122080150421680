import React, { useState, useEffect } from 'react'
import { useSpring, useTrail, animated,  useSprings, config, Spring } from 'react-spring'
import { interpolate } from 'flubber'
import './ImageScroll.css'

const COLORS = [
  'crimson',
  'teal',
  'coral',
  'hotpink',
  'skyblue',
  'salmon',
  'seagreen',
  'peachpuff',
]

// export default class App extends React.Component {
//   state = { y: 0 }
//   el = React.createRef()
//   spring = React.createRef()
//   setY = () => this.setState({ y: Math.round(Math.random() * 750) + 50 })
//   // User interaction should stop animation in order to prevent scroll-hijacking
//   // Doing this on onWheel isn't enough, but just to illustrate ...
//   stop = () => this.spring.current.stop()
//   render() {
//     const y = this.el.current ? this.el.current.scrollTop : 0
//     return (
//       <>
//         <div className="scrolltop-main">
//           <Spring
//             native
//             reset
//             from={{ y }}
//             to={{ y: this.state.y }}
//             ref={this.spring}
//             config={config.slow}>
//             {props => (
//               <animated.div
//                 className="scrolltop-c"
//                 ref={this.el}
//                 onWheel={this.stop}
//                 scrollTop={props.y}>
//                 {COLORS.map(c => (
//                   <div key={c} style={{ height: 200, background: c }} />
//                 ))}
//               </animated.div>
//             )}
//           </Spring>
//         </div>
//         <div className="scrolltop-b" onClick={this.setY} />
//       </>
//     )
//   }
// }


export default function ImageScroll() {
	const el = React.createRef()
	const spring = React.createRef()
	const [y, setY] = useSpring(() => ({ config: { duration: 800 }, y: 0 }));

	const scrollTop = () => setY(Math.round(Math.random() * 750) + 50)

	const yy = el.current ? el.current.scrollTop : 0
	const stop = () => spring.current.stop()
	

	const [EL, setEL] = useState()
	const [springState, setSpringState] = useState()

	useEffect(
	  () => {
	    if (el.current) {
	      setEL(el.current.scrollTop)
	    }
	  },
	  [el]
	)

	useEffect(
	  () => {
	    if (spring.current) {
	      setSpringState(spring.current.getTotalLength())
	    }
	  },
	  [spring]
	)
	
	return (
		<div>
		  <div className="scrolltop-main">
		    <Spring
		      reset
		      from={{ y: yy }}
		      to={{ y: y }}
		      ref={spring}
		      config={config.slow}>
		      {props => (
		        <animated.div
		          className="scrolltop-c"
		          ref={el}
		          // onWheel={stop}
		          scrollTop={props.y}>
		          {COLORS.map(c => (
		            <div key={c} style={{ height: 500, background: c }} />
		          ))}
		        </animated.div>
		      )}
		    </Spring>
		  </div>
		  <div className="scrolltop-b" onClick={scrollTop} />
		</div>
	)



}