import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useSpring, useTrail, animated,  useSprings, Spring, interpolate } from 'react-spring'
import { Container, Row, Col, Form, InputGroup, Navbar} from 'react-bootstrap'
import { useDrag } from 'react-use-gesture'
import clamp from 'lodash-es/clamp'
import { useGesture } from 'react-with-gesture'
import { useLocalStorage, ColOf, RowOf, Center, CenterP } from './Utils'


function Pull(props) {
  const [x, setX] = useState(props.xint);
  const [y, setY] = useState(props.yint);
  const [{ xy }, set] = useSpring(() => ({ xy: [x, y] }))

  function setAll(x, y, velocity) {
    setX(x)
    setY(y)
    set({ config: { mass: velocity, tension: 500 * velocity, friction: 50 } })
    return 
  }
  const bind = useGesture(({ down, delta, velocity }) => {
    velocity = clamp(velocity, 1, 8)
    console.log(delta)
    
    if (delta[0] > 300){
      console.log('next page', props.pageNumber + 1)
      props.setPageNumber(props.pageNumber + 1)
      setAll(props.xint, props.yint, velocity)

      return
    } else if (delta[0] < -300) {
      console.log('previous page', props.pageNumber - 1)
      if (props.pageNumber - 1 < 0) {
        setAll(props.xint, props.yint, velocity)
        return
      }
      props.setPageNumber(props.pageNumber - 1)
      setAll(props.xint, props.yint, velocity)
      return
    }
    setAll(delta[0], delta[1], velocity)

  })
  set({xy: [x, y]})
  return <animated.div {...bind()} style={{ display: 'flex',  justifyContent:'center', alignItems:'center', transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }} >{props.what}</animated.div>
}


function RangeForm(props) {
  return (
    <Form>
      <Form.Group controlId="formBasicRangeCustom">
        <Form.Label>Page Number</Form.Label>
        <Form.Control type="range" min={1} value={props.value} max={props.numPages} custom onChange={e => props.setPageNumber(Number(e.target.value))}/>
      </Form.Group>
    </Form>
  )
}

function PageInput(props) {
  return (
    <Form>
      <Form.Group as={Col}>
        <Form.Control type="number" min={1} required max={props.numPages} value={props.value} onChange={e => props.setPageNumber(Number(e.target.value))}/>
        <Form.Control.Feedback type="invalid">
          Please select a page number.
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  )
}

function FileNameInput(props) {
  return (
    <Form>
      <Form.Group as={Col}>
        <Form.Control type="text" value={props.fileName} onChange={e => props.setFileName(e.target.value)}/>
        <Form.Control.Feedback type="invalid">
          Please select file
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  )
}

function FileName(props) {
  return (
    <Row>
      <Col md={8}>
        <h3>{props.fileName}</h3>
      </Col>
      <Col md={4}>
        <FileNameInput fileName={props.fileName} setFileName={props.setFileName} />
      </Col>
    </Row>
  )
}

function Title(props){
  return <RowOf what={<ColOf what={<h1>PDF Viewer</h1>} />} />
}

function PageNumberControls(props) {
  return (
    <Col>
      <Row>
        <Col md={8}>
          <RangeForm numPages={props.numPages} value={props.pageNumber} setPageNumber={props.setPageNumber} />
        </Col>
        <Col md={4}>
          <PageInput numPages={props.numPages} value={props.pageNumber} setPageNumber={props.setPageNumber} />
        </Col>
      </Row>
    </Col>
  )
}

function PageNumbers(props) {
  return (
    <RowOf what={
      <Col>
        <RowOf what={<PageNumberControls pageNumber={props.pageNumber} numPages={props.numPages} setPageNumber={props.setPageNumber} />} />
        <RowOf what={<ColOf what={<p>Page {props.pageNumber} of {props.numPages}</p>} />} />
      </Col>
    } />
  )
}


function PullableDocumentPage(props) {
  return (
    <Document renderMode={"svg"} file={props.fileName} onLoadSuccess={props.onDocumentLoadSuccess} >
      <Pull xint={props.xint} setPageNumber={props.setPageNumber} pageNumber={props.pageNumber} yint={props.yint} what={<Page scale={1.2} renderMode={"svg"} pageNumber={props.pageNumber} />} />
    </Document>
  )
}

function Meat(props) {
  return (
    <RowOf what={<ColOf what={
      <PullableDocumentPage 
        xint={props.xint} 
        yint={props.yint} 
        pageNumber={props.pageNumber} 
        setPageNumber={props.setPageNumber} 
        onDocumentLoadSuccess={props.onDocumentLoadSuccess} 
        fileName={props.fileName}
      />
    } />} />
  )
}

function MyApp() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useLocalStorage('pageNumber', 1);
  const [fileName, setFileName] = useLocalStorage('file', 'test.pdf');
  const [xint, setXint] = useLocalStorage('xint', 0);
  const [yint, setYint] = useLocalStorage('yint', 0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Row>
      <Col>
        <Title />
        <FileName fileName={fileName} setFileName={setFileName} />
        <PageNumbers pageNumber={pageNumber} numPages={numPages} setPageNumber={setPageNumber} />
        <Meat xint={xint} yint={yint} pageNumber={pageNumber} setPageNumber={setPageNumber} onDocumentLoadSuccess={onDocumentLoadSuccess} fileName={fileName}/>
      </Col>
    </Row>
  );
}


export default MyApp