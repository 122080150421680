import React from 'react'
import './Orb.css'

export default function App(props) {
	return (
		<div
			className={'orb'}
		>
			Hello
		</div>

	)
}