import React from 'react'
import { Button } from 'react-bootstrap'
import { useLocation, useMouse, useWindowSize, useCss, useVideo, useMediaDevices, useDropArea, useGeolocation, useMeasure } from 'react-use';



function useUserMedia(requestedMedia) {
  const [mediaStream, setMediaStream] = React.useState(null);

  React.useEffect(() => {
    async function enableVideoStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          requestedMedia
        );
        setMediaStream(stream);
      } catch (err) {
        // Handle the error
      }
    }

    if (!mediaStream) {
      enableVideoStream();
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach(track => {
          track.stop();
        });
      };
    }
  }, [mediaStream, requestedMedia]);

  return [mediaStream, setMediaStream];
}


export default function App(props) {
  const videoRef = React.createRef();
  const location = useLocation();
  const geolocation = useGeolocation();
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false);
  const [copy, setCopy] = React.useState(isVideoPlaying)
  function handleCanPlay() {
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  const devices = useMediaDevices();

  const [captureOptions, setCaptureOptions] = React.useState({'audio': false, 'video': isVideoPlaying})
  console.log(isVideoPlaying)
  console.log(copy)
  console.log(captureOptions)
  const setVideo = (status) => {
    setIsVideoPlaying(status)
    setCaptureOptions({'audio': false, 'video': status ? { facingMode: "environment" }: status})
  }
  const [mediaStream, setMediaStream] = useUserMedia(captureOptions);

  const [measureRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();
  const [bond, state] = useDropArea({
    onFiles: files => console.log('files', files),
    onUri: uri => console.log('uri', uri),
    onText: text => console.log('text', text),
  });


  const className = useCss({
    color: 'tomato',
    '@media only screen and (max-width: 600px)': {
      color: 'orange',
      '&:hover': {
        color: 'red',
      }
    },
  });

  const mouseRef = React.useRef(null);
  const {docX, docY, posX, posY, elX, elY, elW, elH} = useMouse(mouseRef);

  React.useEffect(() => {
    console.log('hello')
    if (!videoRef.current) return
    console.log('setting srcObject')
    videoRef.current.srcObject = mediaStream
  }, [mediaStream])
  console.log(devices)
  return (
    <>
      <pre>
        {JSON.stringify(location, null, 2)}
      </pre>
      <pre>
        {JSON.stringify(geolocation, null, 2)}
      </pre>
      <pre>
        {JSON.stringify(devices, null, 2)}
      </pre>
      <div ref={measureRef}>
        <div>x: {x}</div>    
        <div>y: {y}</div>
        <div>width: {width}</div>
        <div>height: {height}</div>
        <div>top: {top}</div>
        <div>right: {right}</div>
        <div>bottom: {bottom}</div>
        <div>left: {left}</div>
      </div>
      <div {...bond} style={{ width, height }}>
        Drop something here.
      </div>
      <div className={className}>
        Hover me!
      </div>
      <div>
        <video ref={videoRef} autoPlay onCanPlay={handleCanPlay}/>
        <Button onClick={() => setVideo(!isVideoPlaying)}>Toggle Video</Button>
      </div>
      <div ref={mouseRef}>
        <div>Mouse position in document - x:{docX} y:{docY}</div>
        <div>Mouse position in element - x:{elX} y:{elY}</div>
        <div>Element position- x:{posX} y:{posY}</div>
        <div>Element dimensions - {elW}x{elH}</div>
      </div>
    </>
  )
}