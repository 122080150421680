import { useEffect, useState, useReducer } from 'react';
import { BoardSquare } from './BoardSquare';
import { Piece, Knight, Queen } from './Piece';
import { Types } from './ItemTypes';
import { Row, Button, Col } from 'react-bootstrap' 
import * as _ from 'lodash'
/** Styling properties applied to the board element */

const boardStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
};

const length = 10
const width = 10
/** Styling properties applied to each square element */
const squareStyle = { width: `${100 / width}%`, height: `${100 / length}%` };

function newKnight(team) {
  const id = _.uniqueId()
  return { icon: '♘', type: Types.KNIGHT, id: id, team }
}
function newQueen(team) {
  const id = _.uniqueId()
  return { icon: '♕', type: Types.QUEEN, id: id, team }
}
function newKing(team) {
  const id = _.uniqueId()
  return { icon: '♔', type: Types.KING, id: id, team }
}


export const Board = ({ game }) => {
  const pos = [1, 7]
  const initialState = {}
  
  initialState[pos] = newKing('blue')

  function reducer(state, action) {
    console.log('reducer ', action.type)
    const stateCopy = _.cloneDeep(state)
    let indexX 
    let indexY
    let filteredState
    let id
    let key
    switch (action.type) {
      case 'add':
        stateCopy[action.payload.pos] = action.payload.piece
        return stateCopy
      case 'remove':
        id = action.payload.piece.id

        key = _.find(Object.keys(stateCopy), (key) => {
          return stateCopy[key].id === id
        })

        delete stateCopy[key]
        return stateCopy

      case 'move':
        id = action.payload.piece.id

        key = _.find(Object.keys(stateCopy), (key) => {
          return stateCopy[key].id === id
        })

        delete stateCopy[key]

        stateCopy[action.payload.newPos] = action.payload.piece
        console.log(stateCopy)
        return stateCopy
      case 'update':
        return action.payload.state
      default:
       console.log('Warning: returning default')
       return state
    }
  }


  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => game.observe(state, dispatch));

  function xyFromi(i){
    const x = i % width;
    const y = Math.floor(i / length);
    return [x, y]
  }

  function renderSquare(i) {
    const [x, y] = xyFromi(i)
    const pos = [x, y]
    return (
      <div key={i} style={squareStyle}>
        <BoardSquare pos={pos} game={game}>
          {state[pos] ? <Piece id={state[pos].id} type={state[pos].type} icon={state[pos].icon} team={state[pos].team}/> : null}
        </BoardSquare>
      </div>
    );
  }
  const squares = [];
  for (let i = 0; i < width * length; i += 1) {
    squares.push(renderSquare(i));
  }

  return (
    <>
      <div style={boardStyle}>
        {squares}
      </div>
      <Row>
        <Col>
          <Button onClick={() => (dispatch({type: 'add', 'payload': {'piece': newKnight('red'), pos: [0, 4]}}))} >Add Knight</Button>
        </Col>
        <Col>
          <Button onClick={() => (dispatch({type: 'add', 'payload': {'piece': newQueen('blue'), pos: [7, 9]}}))} >Add Queen</Button>
        </Col>
        <Col>
          <Button onClick={() => (dispatch({type: 'add', 'payload': {'piece': newKing('blue'), pos: [2, 4]}}))} >Add King</Button>
        </Col>
      </Row>
    </>
  );
};
