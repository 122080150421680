import React, { useState, useEffect } from 'react'
import { useSpring, useTrail, animated,  useSprings, Spring } from 'react-spring'
import { interpolate, splitPathString, interpolateAll, separate } from 'flubber'

const SAD = 'M90.546,15.518C69.858-5.172,36.199-5.172,15.515,15.513C-5.173,36.198-5.171,69.858,15.517,90.547 c20.682,20.684,54.341,20.684,75.027-0.004C111.23,69.858,111.229,36.2,90.546,15.518z M84.757,84.758 c-17.494,17.494-45.96,17.496-63.455,0.002c-17.498-17.497-17.496-45.966,0-63.46C38.796,3.807,67.261,3.805,84.759,21.302 C102.253,38.796,102.251,67.265,84.757,84.758z M77.017,74.001c0.658,1.521-0.042,3.286-1.562,3.943 c-1.521,0.66-3.286-0.042-3.944-1.562c-2.893-6.689-9.73-11.012-17.421-11.012c-7.868,0-14.747,4.319-17.522,11.004 c-0.479,1.154-1.596,1.851-2.771,1.851c-0.384,0-0.773-0.074-1.15-0.23c-1.53-0.636-2.255-2.392-1.62-3.921 c3.71-8.932,12.764-14.703,23.063-14.703C64.174,59.371,73.174,65.113,77.017,74.001z M33.24,38.671 c0-3.424,2.777-6.201,6.201-6.201c3.423,0,6.2,2.776,6.2,6.201c0,3.426-2.777,6.202-6.2,6.202 C36.017,44.873,33.24,42.097,33.24,38.671z M61.357,38.671c0-3.424,2.779-6.201,6.203-6.201c3.423,0,6.2,2.776,6.2,6.201 c0,3.426-2.776,6.202-6.2,6.202S61.357,42.097,61.357,38.671z'
const SMILE = 'M51.919,79.544c11.245,0,21.278-6.399,25.562-16.304c0.191-0.443,0.146-0.953-0.117-1.355 c-0.264-0.404-0.714-0.646-1.196-0.647l-48.638-0.06c-0.001,0-0.002,0-0.002,0c-0.479,0-0.926,0.238-1.192,0.637 c-0.266,0.398-0.315,0.904-0.131,1.347C30.339,73.113,40.433,79.544,51.919,79.544z M71.853,65.232 c-4.287,6.369-11.729,10.312-19.934,10.312c-8.401,0-15.917-3.962-20.132-10.361L71.853,65.232z M32.059,37.489 c0-3.423,2.777-6.201,6.2-6.201c3.423,0,6.2,2.777,6.2,6.201c0,3.426-2.777,6.203-6.2,6.203 C34.836,43.692,32.059,40.915,32.059,37.489z M60.175,37.489c0-3.423,2.78-6.201,6.203-6.201c3.424,0,6.2,2.777,6.2,6.201 c0,3.426-2.776,6.203-6.2,6.203C62.955,43.692,60.175,40.915,60.175,37.489z M85.466,103.696H18.231 c-10.053,0-18.23-8.179-18.23-18.229V18.23C0.001,8.178,8.179,0,18.231,0h67.235c10.053,0,18.229,8.178,18.229,18.23v67.235 C103.696,95.518,95.519,103.696,85.466,103.696z M18.231,8.579c-5.322,0-9.652,4.33-9.652,9.651v67.235 c0,5.321,4.33,9.651,9.652,9.651h67.235c5.321,0,9.651-4.33,9.651-9.651V18.23c0-5.321-4.33-9.651-9.651-9.651H18.231z'
let SADs = splitPathString(SAD)
SADs.push('M0,0')
let SMILEs = splitPathString(SMILE)

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    willChange: 'background',
  },
  shape: { width: 300, height: 300, willChange: 'transform' },
}


function makeInterpolator(x, current) {
  let toShape
  if ((Math.floor(x.get()) + 1) % 2) {
    toShape = SMILE
  } else {
    toShape = SAD
  }
  return interpolate(current, toShape)
}

function makeInterpolatorAll(x, current) {
  let toShape
  let fromShape
  if ((Math.floor(x.get()) + 1) % 2) {
    toShape = SMILEs
    fromShape = SADs
  } else {
    toShape = SMILEs
    fromShape = SADs
  }

  return interpolateAll(fromShape, toShape, { single: false })
}

function floor(x){
  return x.get() - Math.floor(x.get())
}



function Animations(props){
  const paths = props.paths.map((path, index) => {
    const id = 'path-' + String(index)
    return <animated.path key={id} d={path} />
  })
  return (
    <svg viewBox="0 0 600 600">
      <animated.g style={props.x}>
        {paths}
      </animated.g>
    </svg>
  )
}


function Animation(props){
  
  return (
    <svg viewBox="0 0 600 600">
      <animated.g style={props.x}>
        <animated.path id="path-1" d={props.path} />
      </animated.g>
    </svg>
  )
}

export default function Faces() {
  
  const [toggle, setToggle] = useState(0);
  const [svgState, setSvgState] = useState([]);

  useEffect(() => {
    const id = setTimeout(() => {
      setToggle(!toggle);
      setSvgState(paths)
    }, 20);
    return () => clearTimeout(id);
  }, [toggle]);

  const [{x}, set] = useSpring(() => ({
    x: 0,     
    config: { mass: 10, tension: 50, friction: 10 },
    onStart: () => set({x: 0.1}),
    onRest: () => set({x: 0}),
  }))
  set({x: x.get() + 0.1})

  const interpolator = makeInterpolatorAll(x, svgState)

  const paths = interpolator.map((item, index) => {
    return item(floor(x))
  })
  return <Animations paths={paths} x={x} />
}
