import * as _ from 'lodash'


export class GameEngine {
  constructor() {
    this.positions = {}
  }
  observe(state, dispatch) {
    console.log('observe')
    this.dispatch = dispatch
    this.positions = {}
    _.forIn(state, (piece, pos) => {
      this.positions[pos] = piece
    })

  }
  movePiece(item, toX, toY) {
    console.log('movePiece')
    
    const posKey = _.find(Object.keys(this.positions), (key) => {
      return this.positions[key].id === item.id
    })

    const piece = this.positions[posKey]

    delete this.positions[posKey]
    
    const tmp = [toX, toY]

    this.positions[tmp] = _.cloneDeep(piece)

    this.dispatch({type: 'update', payload: {'state': this.positions}})
  }
  canMovePiece(item, toX, toY) {
    console.log('canMovePiece')
    const key = _.find(Object.keys(this.positions), (key) => {
      return this.positions[key].id === item.id
    })
    const newLocation = [toX, toY]
    if (this.positions[newLocation]) {
      return false
    }
    const [x, y] = key.split(',')
    const dx = toX - x
    const dy = toY - y
    // console.log(item)
    return item.type_.moveFunc(dx, dy)
  }
  attackSquare(item, aX, aY){
    console.log('attackSquare')
    console.log(this.positions)
    let key
    console.log(item)
    if (item === undefined || item === null){
      return false
    }
    try {
      key = _.find(Object.keys(this.positions), (key) => {
        return this.positions[key].id === item.id
      })    }
    catch(err) {
      console.log(err)
    }

    if (key === undefined ){
      return false
    }
    const attackPos = [aX, aY]
    if (!this.positions[attackPos] || this.positions[attackPos].team === item.team ) {
      return false
    }
    const [x, y] = key.split(',')
    const dx = aX - x
    const dy = aY - y
    return item.type_.moveFunc(dx, dy)
  }
}