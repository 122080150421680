import React from 'react'
const prefix = 'img';

const files = {
	byId: {
		'mario': {
			width: 16,
			height: 32.5,
			viewBox: [0,-0.5,16,32.5],
			data: '<g xmlns="http://www.w3.org/2000/svg" class="frames"><path stroke="#ffe3ab" d="M6 0h5M4 1h6M3 2h6M3 3h11M4 12h4M10 12h1M7 13h2M11 13h1M8 14h2M11 14h1M8 15h2M12 15h1M8 16h2M12 16h1M7 17h3M12 17h1M6 18h3M10 18h1M4 19h9M5 20h8M5 21h8M4 22h9M4 23h7M3 24h1M5 24h6M3 25h2M6 25h5M3 26h3M8 26h3M4 27h4M5 28h1"/><path stroke="#ffa347" d="M10 1h1M9 2h2M6 4h2M9 4h3M3 5h2M6 5h2M10 5h4M3 6h2M7 6h8M3 7h2M7 7h3M11 7h4M3 8h5M4 9h5M5 10h8M8 11h2M14 12h1M13 13h3M13 14h3M13 15h3M14 16h1M9 18h1M12 18h1M0 20h5M0 21h5M0 22h4M1 23h3"/><path stroke="#f83800" d="M3 4h3M8 4h1M2 5h1M5 5h1M8 5h2M2 6h1M5 6h2M1 7h2M5 7h2M10 7h1M1 8h2M8 8h6M1 9h3M9 9h5M3 10h2M5 11h3M8 12h2M3 13h4M9 13h2M2 14h6M10 14h1M12 14h1M1 15h7M10 15h2M1 16h7M10 16h2M13 16h1M15 16h1M1 17h6M10 17h2M13 17h2M0 18h6M11 18h1M13 18h1M0 19h4M15 21h1M14 22h2M11 23h5M4 24h1M11 24h5M2 25h1M5 25h1M11 25h5M0 26h3M6 26h2M11 26h5M0 27h4M11 27h5M0 28h5M1 29h3M1 30h3M2 31h3"/></g>'
		}
	},
	allIds: [
		'mario'
	]
}


function SVG(props) {

	if(props.isHidden) {
		return <svg display='none'>{props.children}</svg>;
	}

	let file = files.byId[props.id];

	if(!file) return null;

	let width   = props.width  || file.width  || null;
	let height  = props.height || file.height || null;
	let viewBox = file.viewBox
		? file.viewBox.join(' ')
		: '0 0 '+props.width+' '+props.height;

	return props.isHidden
		? <svg display='none'>{props.children}</svg>
		:(
		<svg
			xmlns='http://www.w3.org/2000/svg'
			version='1.1'
			preserveAspectRatio='xMinYMin'
			width={width}
			height={height}
			viewBox={viewBox}
		>
			<use xlinkHref={'#'+prefix+'-'+props.id}/>
		</svg>
	);

}


function SVGSource(props) {

	return (
		<SVG isHidden={true}>
			<defs>
				{files.allIds.reduce((defs, fileId, fileIndex)=>{
					const file = files.byId[fileId];
					return defs.concat(
						<g
							key={fileIndex}
							id={prefix+'-'+fileId}
							dangerouslySetInnerHTML={{__html: file.data}}
						>
						</g>
					);
				}, [])}
			</defs>
		</SVG>
	);

}


export {
	SVG,
	SVGSource
}