import React from 'react'
import Particles from "react-tsparticles";
import { makeSVG } from './particleUtils'
import svg from './white-rocket.svg'
import { Row, Col, Form } from 'react-bootstrap'
import './particle.css'
import { CenterP } from './Utils'
import ImageUploader from 'react-images-upload';

function Slider(props){
	return (
		<Form>
		  <Form.Group controlId="formBasicRange">
		    <Form.Label >Number of Nodes</Form.Label>
		    <Form.Control onChange={(e) => props.setNumber(e.target.value)} min={1} max={3000} type="range" />
		  </Form.Group>
		</Form>
	)
}

function ParticlesCont(props){
	return (
		<Particles id="tsparticles" width={'100%'} options={makeSVG(props.svgImage, props.number)} />
	)
}

function CompareSVG(props){
	const svgImage = svg
	const [svgPath, setSvgPath ] = React.useState('')

	function change(file) {
		console.log(file)
		console.log(file[0])
		fileToDataUri(file[0])
	    .then(dataUri => {
	      setSvgPath(dataUri)
	    })
	}
	const [number, setNumber] = React.useState(300)

	return (
		<>
			<Row style={{ height: '50vh', margin: '5px'}}>
			  <Col lg={1} ></Col>
			  <Col lg={5} style={{ height: '100%' }}>
			    <ParticlesCont svgImage={svgPath} number={number} />
			  </Col>
			  <Col lg={5} style={{ height: '100%' }}>
			    <CenterP extraStyle={{ height: '100%' }} what={<img src={svgPath} style={{ width: '100%' }}/>} />
			  </Col>
			  <Col lg={1}></Col>
			</Row>
			<Row>
				<Col>
					<Slider setNumber={setNumber} />
				</Col>
			</Row>
			<ImageUploader
			    withIcon={true}
			    buttonText='Choose images'
			    onChange={change}
			    imgExtension={['.jpg', '.gif', '.png', '.gif', 'svg']}
			    maxFileSize={5242880}
			/>
		</>
	)
}

const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
})


export default function App(props){
	const imageRef = React.useRef(null);

	const [path, setPath ] = React.useState('')

	function change(file) {
		console.log(file[0])
		fileToDataUri(file[0])
		      .then(dataUri => {
		        setPath(dataUri)
		      })
	}
	console.log(svg)
	return (
		<>
			<Row >
				<Col>
					<CompareSVG />
				</Col>
			</Row>
			<Row>
				<Col>
					{path ? <img width={'100%'} height={'60vh'} src={path} ref={imageRef}/> : ''}
				</Col>
			</Row>
		</>
	)
}