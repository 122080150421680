import { useDrop } from 'react-dnd';
import { useState } from 'react'
import { Square } from './Square';
import { Types } from './ItemTypes';
import { Overlay, OverlayType } from './Overlay';

export const BoardSquare = ({ pos, children, game }) => {
  const [x, y] = pos
  const [isSeleceted, setIsSelected] = useState(false)

  const [{ isOver, canDrop, item }, drop] = useDrop(() => ({
    accept: [Types.KNIGHT.name, Types.KING.name, Types.QUEEN.name],
    canDrop: (item) => game.canMovePiece(item, x, y),
    drop: (item) => {
      game.movePiece(item, x, y)
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
      item: monitor.getItem(),
    }),
  }), [game]);


  return (
    <div ref={drop} role="Space" onClick={(e) => (setIsSelected(true))} data-testid={`(${x},${y})`} style={{
      position: 'relative',
      width: '100%',
      height: '100%',
    }}>
      <Square >{children}</Square>
        {isOver && !canDrop && <Overlay type={OverlayType.IllegalMoveHover}/>}
        {!isOver && canDrop && <Overlay type={OverlayType.PossibleMove}/>}
        {isOver && canDrop && <Overlay type={OverlayType.LegalMoveHover}/>}
        {game.attackSquare(item, x, y) && <Overlay type={OverlayType.AttackMove}/>}
        {isSeleceted && <Overlay type={OverlayType.Select}/>}
    </div>
  );
};
