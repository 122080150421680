import React, { useState, useRef, useEffect } from 'react'
import { useWindowDimensions, useContainerDimensions } from './Utils'
import { Container, Row, Col, Form, InputGroup, Navbar} from 'react-bootstrap'


function Component(props){
	const [loaded, setLoaded] = useState(0)
  const myRef = React.useRef()
	const { width, height } = useContainerDimensions(myRef)

  return (
  	<div id='main' ref={myRef} style={{ height: `${Number(Math.random() + 50)}em`}} >
	    <div >
	      width: {width || ''} ~ height: {height || ''}
	    </div>
	    <div 
	    	style={{
		    	height: height,
		    	width: width,
		    	backgroundColor: 'lightgreen',
		    	display: 'flex'
	   		}}
	   		id='hello'
	    >
	  	</div>
	</div>
  );
}

const App = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0)
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    setHeight(elementRef.current.getBoundingClientRect().height);
  }, []); //empty dependency array so it only runs once at render

  return (
    <div ref={elementRef} style={{ height: "100%"}}>
      <div >
        width: {width || ''} ~ height: {height || ''}
      </div>
      <div 
      	style={{
  	    	height: height,
  	    	width: width,
  	    	backgroundColor: 'lightgreen',
  	    	display: 'flex'
     		}}
     		id='hello'
      >
    	</div>
    </div>
  )
}
export default Component