
import React, { useState, useEffect } from 'react'
import { useSpring, useTrail, animated,  useSprings, Spring } from 'react-spring'
import { interpolate } from 'flubber'

const TRIANGLE = 'M20,380 L380,380 L380,380 L200,20 L20,380 Z'
const RECTANGLE = 'M20,20 L20,380 L380,380 L380,20 L20,20 Z'
const styles = {
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    willChange: 'background',
  },
  shape: { width: 300, height: 300, willChange: 'transform' },
}


function SpinItems(items) {
  const [toggle, setToggle] = useState(0);

  const springs = useSprings(
    items.length,
    items.map(() => ({
      transform: toggle > 0 ? "rotate(0deg) scale(1)" : toggle < 0 ? "rotate(-360deg) scale(0)": "rotate(360deg) scale(0)",
      transformOrigin: "center",
      transformBox: "fill-box",
      config: { mass: 1000, tension: 550, friction: 140 },
      // onRest: () => setToggle(-1 * toggle)
    }))
  );

  const animatedIcons = springs.map((style, index) => (
    <animated.g key={index} style={style}><svg width="72" height="72" viewBox="0 -720 720 720">{items[index]}</svg></animated.g>
  ));

  useEffect(() => {  
    if (toggle == 0) {
        setToggle(1)
    }
    
  })
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="695.962"
      height="624.846"
      viewBox="0 0 841.9 595.3"
    >
      {animatedIcons}
    </svg>
  );
}


export default function Triangle() {
  
  const [toggle, setToggle] = useState(0);

  const springs = useSprings(
    1,
    [1].map(() => ({
      transform: toggle > 0 ? "rotate(0deg)" : toggle < 0 ? "rotate(-360deg)": "rotate(360deg)",
      transformOrigin: "center",
      transformBox: "fill-box",
      config: { mass: 1000, tension: 550, friction: 140 },
      onRest: () => setToggle(-1 * toggle),
      onFrame: () => setToggle(-1 * toggle)
    }))
  );

  const interpolator = toggle ? interpolate(TRIANGLE, RECTANGLE): interpolate(RECTANGLE, TRIANGLE)

  const animatedIcons = (number) => {
    return springs.map((style, index) => (
      <animated.g key={index} style={style}><animated.path id="path-1" d={interpolator(number.get())} /></animated.g>
  ))};

  useEffect(() => {  
    if (toggle == 0) {
        setToggle(1)
    }
  })
    
  

  return (
    <Spring
      from={{ color: 'black', number: 0 }}
      to={{
        coords: toggle ? [0, 0] : [50, 50],
        color: toggle ? '#247BA0' : '#70C1B3',
        start: toggle ? '#B2DBBF' : '#B2DBBF',
        end: toggle ? '#247BA0' : '#F3FFBD',
        scale: toggle ? 0.3 : 0.4,
        shape: toggle ? TRIANGLE : RECTANGLE,
        stop: toggle ? '0%' : '50%',
        rotation: toggle ? '0deg' : '45deg',
        number: 1,
      }}
      config={
        { mass: 10, tension: 50 * 1, friction: 50 }
      }
      // onRest={() => setToggle(-1 * toggle)}
      >
      {({
        color,
        scale,
        shape,
        start,
        end,
        stop,
        rotation,
        number,
        coords,
        ...rest
      }) => { 
        const transition = shape.to(interpolator)

      return (
        <animated.div
          style={{
            ...styles.container,
            background: `linear-gradient(to bottom, ${start.get()} ${stop.get()}, ${end.get()} 100%)`,
            ...rest,
          }}>
          <animated.svg
            style={{
              ...styles.shape,
              transform: `scale3d(${scale.get()}, ${scale.get()}, ${scale.get()}) rotate(${rotation.get()}) translate3d(${
                coords.get()[0]
              }px,${coords.get()[1]}px,0)`,
            }}
            version="1.1"
            viewBox="0 0 400 400">
            {animatedIcons(number)}
          </animated.svg>
        </animated.div>
      )}}
    </Spring>
)  
    // return (
    //     <div className="Numbers">
    //       <svg
    //         viewBox="0 0 210 297"
    //         height="250px"
    //         width="250px"
    //         onClick={() => stop()}
    //       >
    //         <animated.path
    //           d={x.to({
    //             range: [0, 1],
    //             output: [
    //               interpolator(x.get()),
    //               interpolator(x.get())
    //             ],
    //           })}
    //         />
    //       </svg>
    //     </div>
    // )
}
