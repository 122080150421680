
const squareStyle = {
  width: '100%',
  height: '100%',
  border: '1px solid gray',
};

export const Square = (props) => {
  const backgroundColor = 'lightgray';
  // const color = black ? 'white' : 'black';
  return (
    <div style={{
      ...squareStyle,
      backgroundColor,
    }}>
      {props.children}
    </div>
  );
};
