import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { Container, Row, Col, Form, InputGroup, Navbar} from 'react-bootstrap'
import { useLocalStorage } from './Utils'
import './backgroundStyles.css'



function ExpandThing(props) {
  const [toggle, setToggle] = useState(1)

  console.log('ExpandThing ', props.duration)
  const stringProps = useSpring({
    config: { duration: props.duration },
    from: { left: '0%', top: '0%', width: '0%', height: '0%', background: 'lightgreen' },
    to: async next => {
      while(1){
        if(toggle) {
          await next({ left: '0%', top: '0%', width: '100%', height: '100%', background: 'lightblue' })
          await next({ height: '50%', background: 'lightgreen' })
          await next({ width: '50%', left: '50%', background: 'lightgoldenrodyellow' })
          await next({ top: '0%', height: '100%', background: 'lightpink' })
          await next({ top: '50%', height: '50%', background: 'lightsalmon' })
          await next({ width: '100%', left: '0%', background: 'lightcoral' })
          await next({ width: '50%', background: 'lightseagreen' })
          await next({ top: '0%', height: '100%', background: 'lightskyblue' })
          await next({ width: '100%', background: 'lightslategrey' })
        } 
        if(!toggle){
          await next({ left: '0%', top: '0%', width: '10%', height: '10%', background: '#ff8040'})
        }
      }
    },
  })
  return (
    <animated.svg onClick={ () => setToggle(!toggle) } id={ "movingBox" } height={"100%"} className="script-box" style={{...stringProps, }} >

    </animated.svg>
  )
}

export default function App() {
  const [duration, setDuration] = useLocalStorage('background-duration', 350) 

  return (
    <>
      <Row>
        <Col>
          <Form>
            <Form.Group controlId="formBasicRangeCustom">
              <Form.Label>Speed: {duration} </Form.Label>
              <Form.Control type="range" min={1} value={duration} max={500} custom onChange={e => setDuration(Number(e.target.value))} />
            </Form.Group>
            <Form.Group >
              <Form.Label></Form.Label>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row style={{height: "100%"}} >
        <Col>
          <ExpandThing duration={duration}/>
        </Col>
      </Row>
    </>
  )

}