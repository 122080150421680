import React from 'react'

function makeSVG(url, numberParticles){
  return {
    detectRetina: true,
    fpsLimit: 60,
    particles: {
      color: {
        value: "#ff0000",
        animation: {
          enable: false,
          speed: 20,
          sync: true
        }
      },
      lineLinked: {
        blink: false,
        color: "random",
        consent: false,
        distance: 30,
        enable: true,
        opacity: 0.3,
        width: 0.5,
        triangles: {
          enable: false,
          color: "#ffffff",
          opacity: 0.1
        }
      },
      move: {
        attract: {
          enable: false,
          rotate: {
            x: 600,
            y: 1200
          }
        },
        bounce: false,
        direction: "none",
        enable: false,
        outMode: "bounce",
        random: false,
        speed: 1,
        straight: false
      },
      number: {
        density: {
          enable: false,
          area: 2000
        },
        limit: 0,
        value: numberParticles
      },
      opacity: {
        animation: {
          enable: true,
          minimumValue: 0.05,
          speed: 2,
          sync: false
        },
        random: false,
        value: 1
      },
      shape: {
        type: "circle"
      },
      size: {
        animation: {
          enable: false,
          minimumValue: 0.1,
          speed: 40,
          sync: false
        },
        random: true,
        value: 1
      }
    },
    polygon: {
      draw: {
        enable: true,
        lineColor: "rgba(255,255,255,0.2)",
        lineWidth: 0.03
      },
      move: {
        radius: 1
      },
      inlineArrangement: "equidistant",
      scale: 1,
      type: "inline",
      url: url
    }
  }
}


export { makeSVG, }