import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { CenterP } from '../Utils'
import { Row, Col } from 'react-bootstrap'
import { useMemo } from 'react';
import { Board } from './Board';
import { GameEngine } from './Game';
import { isMobile } from 'react-device-detect';

const containerStyle = {
  width: '60vh',
  height: '60vh',
  border: '1px solid gray',
};

 function TutorialApp () {
  const game = useMemo(() => new GameEngine(), []);
  return (
  	<div style={containerStyle}>
			<Board game={game}/>
		</div>
	);
};

export default function App() {
	return (
		<Row style={{ height: '100%' }}>
			<DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
				<CenterP what={<TutorialApp />} />
			</DndProvider>
		</Row>
	)
}