/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef, useMemo } from 'react'

import { Group } from '@visx/group';
import { Tree, hierarchy } from '@visx/hierarchy';
import { HierarchyPointNode } from '@visx/hierarchy/lib/types';
import { LinkHorizontal } from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import { Text } from '@visx/text';
import { useContainerDimensions } from './Utils'
import * as _ from 'lodash'


function TextT({ text, width, fill, anchor }){
return (
  <Text
    x={0}
    dy=".33em"
    width={width}
    fontFamily="Arial"
    fill={fill}
    verticalAnchor={anchor}
    style={{ fontSize: fontSize, }}
  >
    {text}
  </Text>
);
}

const peach = '#fd9b93';
const pink = '#fe6e9e';
const blue = '#03c0dc';
const green = '#26deb0';
const plum = '#71248e';
const lightpurple = '#374469';
const white = '#ffffff';
export const background = '#272b4d';


const rawTree = {
  name: '/',
  id: _.uniqueId(),
  children: [
    {
      name: 'Users',
      id: _.uniqueId(),
      children: [
        { name: 'Draft.docx',
        	id: _.uniqueId(), },
        { name: 'Second_draft.docx', id: _.uniqueId(), },
        { name: 'Final.docx', id: _.uniqueId(), },
        {
          name: 'Project',
          id: _.uniqueId(),
          children: [
            {
              name: 'Models.ppt',
              id: _.uniqueId(),
            },
            {
              name: 'Items',
              id: _.uniqueId(),
              children: [
                {
                  name: 'item1.txt',
                  id: _.uniqueId(),
                },
                {
                  name: 'item2.txt',
                  id: _.uniqueId(),
                },
                {
                  name: 'item3.txt',
                  id: _.uniqueId(),
                },
              ],
            },
          ],
        },
      ],
    },
    { name: '.zshrc', other: 'other data', id: _.uniqueId(), },
    {
      name: 'bin',
      children: [{ name: 'zsh', id: _.uniqueId(), }, { name: 'bash', id: _.uniqueId(), }, { name: 'python', id: _.uniqueId() }],
    },
  ],
};

/** Handles rendering Root, Parent, and other Nodes. */
function Node({ node }) {
	const ref = useRef(null);

	const [width, setWidth] = useState(40);
	const [height, setHeight] = useState(20)

  const centerX = -width / 2;
  const centerY = -height / 2;
  const isRoot = node.depth === 0;
  const isParent = !!node.children;
  const isEdit = node.data.edit;

  if (isRoot) return <RootNode node={node} />;
  if (isParent) return <ParentNode node={node} />;
  if (isEdit) return <EditNode node={node} />;

  return <TerminalNode node={node} />;
}
function TerminalNode({ node }) {
	const [width, setWidth] = useState(40);
	const [height, setHeight] = useState(20)
  const centerX = -width / 2;
  const centerY = -height / 2;

  return (
    <Group top={node.x} left={node.y}>
      <text
        dy=".33em"
        fontSize={fontSize}
        fontFamily="Arial"
        textAnchor="middle"
        fill={green}
				style={{ pointerEvents: 'none', flex: 1, flexWrap: 'wrap' }}
      >
        {node.data.name}
      </text>
    </Group>
  );
}

function RootNode({ node }) {
  console.log(node.data)
  return (
    <Group top={node.x} left={node.y}>
      <circle r={12} fill="url('#lg')" />
      <text
        dy=".33em"
        fontSize={fontSize}
        fontFamily="Arial"
        textAnchor="middle"
        style={{ pointerEvents: 'none', flex: 1, flexWrap: 'wrap' }}
        fill={plum}
      >
        {node.data.name}
      </text>
    </Group>
  );
}

function EditNode({ node }) {
  const width = 40;
  const height = 20;
  const centerX = -width / 2;
  const centerY = -height / 2;

  return (
		<textarea></textarea>
  );
}

const fontSize = '1.2em'

function ParentNode({ node }) {
  const width = 40;
  const height = 20;
  const centerX = -width / 2;
  const centerY = -height / 2;

  return (
    <Group top={node.x} left={node.y}>
      <TextT text={node.data.name} width={width} fill={white} textAnchor={"middle"}/>
    </Group>
  );
}

const defaultMargin = { top: 10, left: 80, right: 80, bottom: 10 };


export default function Example(props) {
  console.log(props)
  const sizeRef = props.sizeRef
  const margin = defaultMargin
	const { width, height } = useContainerDimensions(sizeRef)
	console.log(width, height)
  const data = useMemo(() => hierarchy(rawTree), []);

  const yMax = height - margin.top - margin.bottom;
  const xMax = width - margin.left - margin.right;

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <LinearGradient id="lg" from={peach} to={pink} />
      <rect width={width} height={height} rx={14} fill={background} />
      <Tree root={data} size={[yMax, xMax]}>
        {tree => (
          <Group top={margin.top} left={margin.left}>
            {tree.links().map((link, i) => (
              <LinkHorizontal
                key={`link-${i}`}
                data={link}
                stroke={lightpurple}
                strokeWidth="1"
                fill="none"
              />
            ))}
            {tree.descendants().map((node, i) => (
              <Node key={`node-${i}`} node={node} />
            ))}
          </Group>
        )}
      </Tree>
    </svg>
  );
}