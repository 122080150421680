import React, { useState, useEffect, useReducer } from 'react'
import { useDrag } from 'react-use-gesture'
import { scale, dist } from 'vec-la'
import { Container, Row, Col, Form, InputGroup, Navbar} from 'react-bootstrap'
import { useSpring, useTrail, animated, useSprings, Spring, to, config } from 'react-spring'
import { useLocalStorage, useKeyPress, useContainerDimensions, useWindowDimensions } from './Utils'
import { useMeasure } from 'react-use';
import RocketSVG from './rocket.svg'
import * as _ from 'lodash'
import './Rocket.css'
import stars from './stars'
import Particles from "react-tsparticles";
import StaryNight from './StaryNight'
import './stars.css'
import * as Victor from 'victor'



function Phaser(props) {
  if (!props.phasersOn) {
    return <div class="laser-beam"></div>
  } else {
    return <div ></div>
  }
}


function Rocket(props) {
  const ref = React.useRef()
  const sPress = useKeyPress("s")
  console.log('sPress is ', sPress)
  const shieldStatus = sPress || false
  const dims = useContainerDimensions(ref)
  console.log(dims)
  console.log('Shield Status is: ', shieldStatus)
  const id = 'rocket'

  const [storagePos, setStoragePos] = useLocalStorage('rocketPosition', [0, 0])
  if (storagePos === [null, null]) {
    setStoragePos([0, 0])
  }

  const [storageAngle, setStorageAngle] = useLocalStorage('rocketAngle', 0)
  if (storageAngle === null) {
    setStorageAngle(0)
  }

  const [{ pos }, set] = useSpring(() => ({ pos: storagePos, config: { clamp: true } }))
  const [{ angle }, setAngle] = useSpring(() => ({ 
    angle: storageAngle, 
    config: config.wobbly, 
    delay: 80,
    onRest: () => ([setStorageAngle(angle.get()),setStoragePos(pos.get())]) 
  }))

  const fPress = useKeyPress("f");
  const [phasersOn, setPhasersOn] = useLocalStorage('rocketPhasersStatus', 1)

  const [engineStatus, setEngineStatus] = useState(0)
  const [shipVelocity, setShipVelocity] = useState(0)
  const [shipDirection, setShipDirection] = useState([])

  if (fPress && phasersOn) {
    const creationTime = new Date().getTime() / 1000;
    props.objects.push({ id: _.uniqueId(), type: 'bullet', angle: angle.get() - (Math.PI / 2), pos: pos.get(), creationTime })
    props.setObjects(_.cloneDeep(props.objects))
    console.log('fire')
    setPhasersOn(0)
    setTimeout(() => {
      console.log('turning phasersOn')
      setPhasersOn(1)
    }, 300);
  }

  const bind = useDrag(
    ({ xy, previous, down, movement, velocity, direction }) => {
      console.log('direction is ', direction)
      // setShipDirection(_.cloneDeep(direction))
      const pos = movement
      console.log('pos is ', pos)
      setEngineStatus(1)
      const scaledVelocity = scale(direction, velocity)
      if (pos[0] === NaN && pos[1] === NaN) {
        console.log('catching ')
        console.log(storagePos)
        pos = storagePos
      }
      set({ pos, immediate: down, config: { velocity: scaledVelocity, decay: true, clamp: true } })
      if (dist(xy, previous) > 10 || !down) {
        const newAngle = (Math.atan2(direction[1], direction[0]) || 0)
        console.log('other angle could be: ', newAngle)
        console.log('other angle could be: ', Math.acos(direction[1]))
        const vDir = Victor.fromArray(direction)

        console.log('other angle could be: ', vDir.horizontalAngle())
        setAngle({ angle: newAngle })
        
      }
      // props.dispatch({type: 'update', 'payload': { magnitude: scaledVelocity, angle: Victor.fromArray(direction).horizontalAngle(), pos: pos, direction: direction }})
    },
    { initial: () => pos.get() }
  )
  console.log('facing: ', angle.get(), ' at ', pos.get())
  

  const vPos = Victor.fromArray(pos.get())
  console.log('vpos ', vPos)

  return (
      <animated.div
          id={id}
          ref={ref}
          className={[engineStatus ? 'enginesOn' : '', shieldStatus ? 'shielded' : '', 'ship', 'rocket'].join(' ') }
          {...bind()}
          style={{ transform: to([pos, angle], ([x, y], a) => `translate3d(${x}px,${y}px,0) rotate(${a}rad)`) }}
        >
          <>
            <RocketSvg pos={pos} angle={angle} />
            <Phaser phasersOn={phasersOn}/>
          </>
      </animated.div>
  )
}

function RocketSvg(props) {
  return (
    <animated.svg width={'100%'} height={'100%'} style={{ transform: to([props.pos, props.angle], ([x, y], a) => `translate3d(${x}px,${y}px,0) rotate(${a}rad)`) }}>
      <animated.g transform="scale(0.010000,-0.010000)"
      fill="#0f0000" stroke="none">
        <animated.path d="M4290 12717 c-517 -244 -979 -517 -1320 -781 -137 -106 -362 -331
        -428 -429 -124 -181 -162 -329 -237 -922 -170 -1355 -304 -3296 -331 -4779
        l-7 -379 -101 -47 c-265 -125 -732 -393 -1036 -596 -508 -338 -764 -605 -815
        -849 -19 -89 -19 -232 0 -363 82 -576 505 -1461 1015 -2124 119 -155 370 -411
        396 -406 38 7 57 113 99 558 92 979 210 1418 439 1628 53 48 154 98 167 84 3
        -4 20 -43 38 -87 44 -112 85 -164 160 -203 l61 -33 0 -57 c0 -141 42 -467 66
        -513 15 -29 25 -32 169 -49 169 -19 509 -30 1123 -37 l612 -6 0 271 0 272 95
        0 95 0 0 -270 0 -270 343 0 c818 1 1513 32 1551 70 23 23 44 162 74 498 l7 83
        56 23 c108 44 148 88 205 229 38 92 36 92 121 51 73 -35 170 -143 226 -252
        144 -278 218 -644 297 -1467 41 -426 60 -525 96 -525 23 0 225 200 327 323
        246 297 498 697 707 1122 357 727 475 1299 329 1587 -68 133 -259 339 -459
        495 -296 229 -934 617 -1326 804 l-121 58 -6 403 c-13 743 -50 1536 -118 2488
        -94 1330 -230 2565 -320 2919 -100 394 -679 875 -1629 1355 -207 105 -423 206
        -437 206 -5 -1 -87 -38 -183 -83z"/>
        <animated.path d="M3290 1908 c-100 -10 -165 -39 -196 -85 -130 -196 74 -815 456 -1388
        173 -259 351 -435 442 -435 140 0 256 427 327 1210 24 252 46 589 39 595 -6 6
        -349 62 -488 79 -139 17 -393 36 -455 35 -22 -1 -78 -6 -125 -11z"/>
        <animated.path d="M5405 1909 c-185 -14 -719 -89 -733 -103 -5 -6 16 -319 39 -571 63
        -713 168 -1156 290 -1224 132 -74 475 333 744 884 124 254 189 439 221 626 37
        225 -13 347 -155 378 -73 16 -271 21 -406 10z"/>
      </animated.g>
    </animated.svg>
  )
}

const Stars = React.memo(props => {
  console.log("Greeting Comp render");
  return <StaryNight state={props.state} width={props.width} height={props.height} />
});

function Objects(props){
  const [measureRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();
  return (
    <animated.div ref={measureRef} width={'100%'} height={'100%'} >
      <Stars state={props.state} width={width} height={height} />
      {
        props.what.map((What, index) => {
          return <What key={index} dispatch={props.dispatch} objects={props.objects} setObjects={props.setObjects}/>
        })
      }
    </animated.div>
  )
}



function App(props){
  console.log('Rendering Simulation')
  const [objects, setObjects] = useState([])

  const initialState = { magnitude: 0, random: false, angle: 0, pos: [0, 0], direction: [0, 0] }
  function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return { 
          magnitude: action.payload.magnitude || state.magnitude,
          angle: action.payload.angle || state.angle, 
          pos: action.payload.pos || state.pos, 
          direction: action.payload.pos || state.direction, 
          random: action.payload.pos || state.random, 
        }
      default:
       return initialState
    }
  }


  const [state, dispatch] = useReducer(reducer, initialState);


  return (
    <>
      <Row id={ 'rootContainer' } style={{ height: "100%" }}>
        <Col>
          <Objects what={[Rocket]} state={state} dispatch={dispatch} objects={objects} setObjects={setObjects} /> 
        </Col>
      </Row>
    </>
  ) 
}



export default App