import React, { useState, useEffect } from 'react'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";
import { Container, Row, Col, Navbar, Card, Nav, Button} from 'react-bootstrap'
import Slide from './Slide'
import Rocket from './Rocket'
import ImageScroll from './ImageScroll'
import Faces from './Faces'
import Pdf from './Pdf'
import Pdfs from './Pdfs'
import Shapes from './Shapes'
import Areas from './Area'
import Stack from './Stack'
import Dims from './Dims'
import Tree from './Tree'
import Animomap from './Animomap'
import FileUI from './FileUI'
import Native from './Nt'
import Background from './Background'
import { ErrorNotFound } from './Errors'
import Explore from './Explore'
import Orb from './Orb'
import Use from './Use'
import Map_ from './Map'
import Reduce from './Reduce'
import Particle from './Particle'
import Context from './Context'
import Games from './Games/Games'
import * as _ from 'lodash'


function NavApps(props){
	let { path, url } = useRouteMatch();
	console.log(path, ' ', url)
	return (
		<Row >
			<Col  >
				<h1>Apps</h1>
				{routes.map((route, index) => (
					<Row key={index}>
						<Col />
						<Col lg={6}>
			  			<Card key={index} className="text-center">
			  			  <Card.Header>{route.name}</Card.Header>
			  			  <Card.Body>
			  			    <Card.Title>{route.name}</Card.Title>
			  			    <Card.Text>
			  			      With supporting text below as a natural lead-in to additional content.
			  			    </Card.Text>
			  			    <Button as={Link} to={`${_.trimEnd(path, '/')}${route.path}`} variant="primary">View</Button>
			  			  </Card.Body>
			  			</Card>
			  			<br/>
			  		</Col>
			  		<Col />
					</Row>
				))}
			</Col>
		</Row>
	)
}

function Apps(props) {
	let { path, url } = useRouteMatch();
	console.log(path, ' ', url)
  return (
  	<Row style={{height: "100%"}}>
  		<Col>
		    <Switch>
		      {routes.map((route, index) => (
		        <Route
		          key={index}
		          path={`${path}${route.path}`}
		          exact={route.exact}
		          render={(p) => (
		              <route.main {...p} width={props.width} height={props.height} sizeRef={props.sizeRef} />
		          )}
		        />
		      ))}
          <Route render={(p) => (
              <ErrorNotFound {...p} path={`${path}`} />
            )} />
		    </Switch>
		   </Col>
	  </Row>
	)
}

const routes = [
  {
    path: "/",
    main: NavApps,
    exact: true,
    name: 'Default'
  },
  {
    path: "/slide",
    main: Slide,
    name: 'Slide'
  },
  {
    path: "/rocket",
    main: Rocket,
    name: 'Rocket'
  },
  {
    path: "/scroll",
    main: ImageScroll,
    name: 'Scroll'
  },
  {
    path: "/faces",
    main: Faces,
    name: 'Faces'
  },
  {
    path: "/pdf",
    main: Pdf,
    name: 'Pdf'
  },
  {
    path: "/pdfs",
    main: Pdfs,
    name: 'Pdfs'
  },
  {
    path: "/shapes",
    main: Shapes,
    name: 'Shapes'
  },
  {
    path: "/areas",
    main: Areas,
    name: 'Areas'
  },
  {
    path: "/stack",
    main: Stack,
    name: 'Stack'
  },
  {
    path: "/dims",
    main: Dims,
    name: 'Dims'
  },
  {
    path: "/tree",
    main: Tree,
    name: 'Tree'
  },
  {
    path: "/animomap",
    main: Animomap,
    name: 'Animomap'
  },
  {
    path: "/file",
    main: FileUI,
    name: 'FileUI'
  },
  {
    path: "/native",
    main: Native,
    name: 'Native'
  },
  {
    path: "/background",
    main: Background,
    name: 'Background'
  },
  {
    path: "/explore",
    main: Explore,
    name: 'Explore'
  },
  {
    path: "/orb",
    main: Orb,
    name: 'Orb'
  },
  {
    path: "/use",
    main: Use,
    name: 'Use'
  },

  {
    path: "/map",
    main: Map_,
    name: 'Map'
  },
  {
    path: "/reduce",
    main: Reduce,
    name: 'Reduce'
  },
  {
    path: "/particle",
    main: Particle,
    name: 'Particle'
  },
  {
    path: "/context",
    main: Context,
    name: 'Context'
  },
  {
    path: "/games",
    main: Games,
    name: 'Games'
  },
];

export default Apps