import React, { useReducer } from 'react';
import { useMeasure } from 'react-use';
import { useLocalStorage } from './Utils'
import { Button, Form, Row, Col, InputGroup, FormControl, Input } from 'react-bootstrap';
import particles from './particles'
import Particles from "react-tsparticles";


function App(props) {
  const [startingCount, setStartingCount] = useLocalStorage('count', 0)
  const [inputValue, setInputValue] = React.useState(0)
  const [measureRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();
  const initialState = { count: startingCount }
  function reducer(state, action) {
    if (action.payload){
      setStartingCount(state.count + Number(action.payload))
      return { count: state.count + Number(action.payload)}
    }
    switch (action.type) {
      case 'increment':
        setStartingCount(state.count + 1)
        return { count: state.count + 1 }
      case 'decrement':
        setStartingCount(state.count - 1)
        return { count: state.count - 1 }
      case 'reset':
        setStartingCount(0)
        return {count: state.count = 0}
      default:
       return { count: state.count  }
    }
  }


  const [state, dispatch] = useReducer(reducer, initialState);
  console.log('state is ', state)
  return (
    <Row>
      <Col>
        <h1>{state.count}</h1>
          <Row>
            <Col ></Col>
            <Col lg={8}>
              <Particles
                id="tsparticles"
                options={particles(state.count)}
              />
            </Col>
            <Col ></Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <InputGroup ref={measureRef} className="mb-3">
                <InputGroup.Prepend>
                  <Button variant="secondary" onClick={() => dispatch({ type: 'increment'})}>Increment</Button>
                  <Button variant="secondary" onClick={() => dispatch({ type: 'decrement'})}>Decrement</Button>
                  <Button variant="secondary" onClick={() => dispatch({ type: 'reset'})}>Reset</Button>
                  <InputGroup.Text variant="secondary">Adjust by: </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl style={{ height: height }} onChange={(e) => setInputValue(e.target.value)}/>
                <InputGroup.Append>
                  <Button variant="secondary" onClick={() => dispatch({ payload: inputValue})}>Adjust</Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
      </Col>
    </Row>
  )
}

export default App