import React, { Component } from 'react';
import GoogleMap from 'google-map-react';
import { useGeolocation } from 'react-use';
import { Spinner } from 'react-bootstrap';
import { CenterP } from './Utils'

const mapStyles = {
  width: '100%',
  height: '100%'
}

const markerStyle = {
  height: '50px',
  width: '50px',
  marginTop: '-50px'
}

const imgStyle = {
  height: '100%'
}


const Marker = ({ title }) => (
  <div style={markerStyle}>
    <img style={imgStyle} src="https://res.cloudinary.com/og-tech/image/upload/s--OpSJXuvZ--/v1545236805/map-marker_hfipes.png" alt={title} />
    <h3>{title}</h3>
  </div>
);


function MapDiv(props) {
  return (
    <div >
      <GoogleMap
        style={mapStyles}
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
        center={{ lat: props.geolocation.latitude, lng: props.geolocation.longitude }}
        zoom={14}
      >
        <Marker
        title={'Current Location'}
        lat={props.geolocation.latitude}
        lng={props.geolocation.longitude}
      >
        </Marker>
      </GoogleMap>
    </div>
  )
}

function AppSpinner(props){
  return (
    <CenterP what={<Spinner role={'status'} animation={'border'} />} />
  )
}

function App(props) {
  const geolocation = useGeolocation();
  const { loading } = geolocation
  console.log(loading)
  return loading  ? <AppSpinner/> : <MapDiv geolocation={geolocation} />
}

export default App;