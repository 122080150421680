import React, { useState, useEffect } from 'react'
import { useSpring, useTrail, animated,  useSprings, Spring } from 'react-spring'
import { interpolate, splitPathString, interpolateAll, separate } from 'flubber'
import SimplexNoise from 'simplex-noise'
import { useLocalStorage, ColOf, RowOf, Center, CenterP } from './Utils'

const simplex = new SimplexNoise();

const shapeOne = 'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'
const shapeTwo = 'M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z'
const shapeThree = 'M7 2v11h3v9l7-12h-4l4-8z'
const shapes = [shapeOne, shapeTwo, shapeThree]

function isTime(t) {
	return (Math.floor(t.get()) + 1) % 2
}

function makeInterpolator(t, current) {
  let toShape
  if (isTime(t)) {
    toShape = shapeOne
  } else {
    toShape = shapeTwo
  }
  return interpolate(current, toShape, { maxSegmentLength: 0.1 })
}

function floor(t){
  return t.get() - Math.floor(t.get())
}

function Animation(props){
  return (
    <svg viewBox="0 0 25 25" >
	    <defs>
	      <linearGradient id="gradient" gradientTransform="rotate(90)">
	        <stop id="gradientStop1" offset="0%" stopColor="var(--startColor)" />
	        <stop id="gradientStop2 " offset="100%" stopColor="var(--stopColor)" />
	      </linearGradient>
	    </defs>
      <animated.g style={{...props.t}}>
        <animated.path fill={"url('#gradient')"} id={"path-1"} d={props.path} />
      </animated.g>
    </svg>
  )
}

function map(n, start1, end1, start2, end2) {
  return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}


function noise(x, y) {
  return simplex.noise2D(x, y);
}

let hueNoiseOffset = 0
const noiseStep = 0.5

export default function Shapes() {
  
  const [toggle, setToggle] = useState(1)
  const [index, setIndex] = useState(0)
  const [svgState, setSvgState] = useState(shapeOne)

  // const [hueNoiseOffset, setHueNoiseOffset] = useState(0)

  useEffect(() => {
    const id = setTimeout(() => {

      if (toggle){
      	if (index + 1 >= shapes.length) {
      		setIndex(0)
      	} else {
      		setIndex(index + 1)
      	}
      	const path = shapes[index]
      	setSvgState(path)
      }

      
    }, 200);
    return () => clearTimeout(id);
  })

  function onRest() {
  	console.log('Running onRest')
  	setToggle(true)
  	set({t: 0})
  }

  function onStart() {
  	console.log('Running onStart')
  	set({t: 0.1})
  }

  const [{ t }, set] = useSpring(() => ({
    t: 0, 
    // from: { t: 0 },
    // to: { t: 1 },    
    config: { mass: 100, tension: 50, friction: 1 },
    onStart: () => onStart(),
    onRest: () => onRest(),
  }))
  set({t: t.get() + 0.1})
  
  const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
  const hue = map(hueNoise, -1, 1, 0, 360);
  document.documentElement.style.setProperty("--startColor", `hsl(${hue}, 100%, 75%)`);
  document.documentElement.style.setProperty("--stopColor", `hsl(${hue + 60}, 100%, 75%)`);
	
	hueNoiseOffset += noiseStep / 6
  const interpolator = makeInterpolator(t, svgState)

  const path = interpolator(floor(t))
  return <Animation path={path} t={t} />
}

function Shapes2() {
  const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
  const hue = map(hueNoise, -1, 1, 0, 360);

  const [index, setIndex] = useLocalStorage('index', 0)
  const [startColor, setStartColor] = useState(`hsl(${hue}, 100%, 75%)`)
  const [stopColor, setStopColor] = useState(`hsl(${hue + 60}, 100%, 75%)`)


  let interpolator
  if (index + 1 >= shapes.length) {
  	interpolator = interpolate(shapes[index], shapes[0], { maxSegmentLength: 0.1 })
  } else {
  	interpolator = interpolate(shapes[index], shapes[index + 1], { maxSegmentLength: 0.1 })
  } 
  
  function onRest(whatever){
  	console.log('running rest')
  	if (index + 1 >= shapes.length) {
  		setIndex(0)
  	} else {
  		setIndex(index + 1)
  	}
  	return
  }
  function onFrame(whatever) {
	  const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
	  const hue = map(hueNoise, -1, 1, 0, 360);

	  setStartColor(`hsl(${hue}, 100%, 75%)`)
	  setStopColor(`hsl(${hue + 60}, 100%, 75%)`)
		
		hueNoiseOffset += noiseStep / 6

  }
  console.log('Shapes2')
  return (
    <Spring reset native from={{ t: 0, background:startColor }} to={{ t: 1, background: stopColor }} onRest={onRest} onStart={onFrame} config={{ mass: 4, friction: 100 }} >
      {(props) => {
      	return (
      		<svg viewBox="0 0 25 25" >
	      		<defs>
	      		  <linearGradient id="gradient" gradientTransform="rotate(90)">
	      		    <stop id="gradientStop1" offset="0%" stopColor={startColor} />
	      		    <stop id="gradientStop2 " offset="100%" stopColor={stopColor} />
	      		  </linearGradient>
	      		</defs>
		      	<animated.g>
		      		<animated.path fill={"url('#gradient')"} d={props.t.to(interpolator)} />
		      	</animated.g>
		      </svg>
      	)
      }}
    </Spring>
  )
}