import React, { useState, useEffect } from 'react'
import { useSpring, useTrail, animated,  useSprings, interpolate, to, Spring } from 'react-spring'
import { Container, Row, Col, Navbar} from 'react-bootstrap'
import { useDrag } from 'react-use-gesture'
import { MarioSprite } from './Mario'
import { Logo, makeLogoInPieces } from './Logo'
import clamp from 'lodash-es/clamp'
import { useGesture } from 'react-with-gesture'
import { Center, CenterP, RowOf, useLocalStorage, useWindowDimensions } from './Utils'
import { render } from 'react-dom'
import swap from 'lodash-move'

function Pull(props) {
  // const [init, setInit] = useLocalStorage('init', [])
  const init = [props.x, props.y + 800]
  console.log(init)
  const [{ xy }, set, stop] = useSpring(() => ({ xy: init }))
  const bind = useGesture(({ down, delta, velocity }) => {
    velocity = clamp(velocity, 1, 8)
    set({ xy: down ? delta : delta, config: { mass: velocity, tension: 500 * velocity, friction: 50 } })
    // set({ xy: delta, config: { mass: velocity, tension: 500 * velocity, friction: 50 } })
    // setInit(delta)
  })
  set({ xy: init})
  return (
    <animated.g {...bind()} style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }} >
      <animated.rect
        // {...bind()}  
        // style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }}
        x={props.x} 
        y={props.y} 
        width={props.width} 
        height={props.height} 
        fill="#ff8040" 
        stroke="#ff8040" 
        />
    </animated.g>
  )
}

function SpinItems(items) {
  const [toggle, setToggle] = useState(0);

  const springs = useSprings(
    items.length,
    items.map((item, index) => ({
      transform: toggle > 0 ? "rotate(0deg) scale(1)" : toggle < 0 ? "rotate(-360deg) scale(0)": "rotate(360deg) scale(0)",
      transformOrigin: "center",
      transformBox: "fill-box",
      config: { mass: 1000, tension: 550, friction: 140 },
      // onRest: () => setToggle(-1 * toggle)
    }))
  );
  const animatedIcons = springs.map((style, index) => (
    <animated.g key={index} style={style}>{items[index]}</animated.g>
  ));

  useEffect(() => {  
    if (toggle == 0) {
        setToggle(1)
    }
    
  })
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="695.962"
      height="624.846"
      viewBox="0 0 841.9 595.3"
    >
      {animatedIcons}
    </svg>
  );
}

const trans = (r, s) => `rotateY(${r / 90}deg) rotateZ(${r}deg) scale(${s})`

const to_ = i => ({ scale: 1, rot: 0 , delay: i * 500  })

function random () { return -50 + Math.random() * 100 }

const from = i => ({ x: random(), y: random(), rot: random(), scale: 0.11, })

function BuildLogo(props) {
  const pieces = [
    <animated.rect x="504" y="-612" width="108" height="108" fill="#ff8040" stroke="#ff8040" />,
    <animated.rect x="0" y="-720" width="504" height="720" fill="#ff8040" stroke="#ff8040" />,
    <animated.rect x="0" y="-504" width="720" height="504" fill="#ff8040" stroke="#ff8040" />,
    <animated.rect x="504" y="-648" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <animated.rect x="504" y="-684" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <animated.rect x="540" y="-648" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <animated.rect x="612" y="-540" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <animated.rect x="648" y="-540" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <animated.rect x="612" y="-576" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
  ]
  const [springs, setSprings] = useSprings(pieces.length, i => ({ ...to_(i), from: from(i) })) 
  
  const square = 720
  return (
    <>
      <Row style={{ height: 2 * square, overflow: 'visible', padding: '5em' }} >
        <Col>
          <RowOf what={Center(Logo)} />
        </Col>
      </Row>
      <Row>
        <Col> 
          <CenterP what={
            <svg xmlns="http://www.w3.org/2000/svg" width={square} height={square} viewBox="0 -670 740 740">
            {
              springs.map(({xx, yy, rot, scale}, i) => {
                return <animated.g style={{ transform: to([rot, scale], trans) }} key={i}>{pieces[i]}</animated.g>
              })
            }
            </svg>
          }
          />
        </Col>
      </Row>
    </>
  )

}



export default BuildLogo;

