import React, { useState, useEffect } from 'react'


function Logo() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="720" height="720" viewBox="0 -720 720 720">

        <rect x="504" y="-612" width="108" height="108" fill="#ff8040" stroke="#ff8040" />
        <rect x="0" y="-720" width="504" height="720" fill="#ff8040" stroke="#ff8040" />
        <rect x="0" y="-504" width="720" height="504" fill="#ff8040" stroke="#ff8040" />
        <rect x="504" y="-648" width="36" height="36" fill="#ff8040" stroke="#ff8040" />
        <rect x="504" y="-684" width="36" height="36" fill="#ff8040" stroke="#ff8040" />
        <rect x="540" y="-648" width="36" height="36" fill="#ff8040" stroke="#ff8040" />
        <rect x="612" y="-540" width="36" height="36" fill="#ff8040" stroke="#ff8040" />
        <rect x="648" y="-540" width="36" height="36" fill="#ff8040" stroke="#ff8040" />
        <rect x="612" y="-576" width="36" height="36" fill="#ff8040" stroke="#ff8040" />
      </svg>
    );
}

function makeLogoInPieces() {
  return [
    <rect x="504" y="-612" width="108" height="108" fill="#ff8040" stroke="#ff8040" />,
    <rect x="0" y="-720" width="504" height="720" fill="#ff8040" stroke="#ff8040" />,
    <rect x="0" y="-504" width="720" height="504" fill="#ff8040" stroke="#ff8040" />,
    <rect x="504" y="-648" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <rect x="504" y="-684" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <rect x="540" y="-648" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <rect x="612" y="-540" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <rect x="648" y="-540" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
    <rect x="612" y="-576" width="36" height="36" fill="#ff8040" stroke="#ff8040" />,
  ]
}

export { Logo,  makeLogoInPieces};