import React from 'react'
import Particles from "react-tsparticles";
import './stars.css'
import useScrollOnEdges from 'react-scroll-on-edges'

function makeMove(magnitude, angle, direction, random=false) {
  return {
    collisions: true,
    enable: true,
    speed: magnitude,
    direction: direction,
    random: random,
    straight: true,
    out_mode: "out",
    attract: {
      enable: false,
      rotateX: 600,
      rotateY: 1200
    },
    angle: {
      value: angle
    }
  }
}

function makeStars(move, number=400) {
  return {
    color: {
      value: "#fff"
    },
    number: {
      density: {
        enable: true,
        area: 1080
      },
      limit: 0,
      value: number
    },
    opacity: {
      animation: {
        enable: true,
        minimumValue: 0.05,
        speed: 0.25,
        sync: false
      },
      random: {
        enable: true,
        minimumValue: 0.05
      },
      value: 1
    },
    shape: {
      type: "circle"
    },
    size: {
      random: {
        enable: true,
        minimumValue: 0.5
      },
      value: 1
    },
    move: move
  }
}


export default function App(props) {
  const { magnitude, angle, pos, direction, random } = props.state 

  let data = {
    backgroundMode: {
      enable: false
    },
    background: {
      color: "#000"
    },
    detectRetina: true,
    fpsLimit: 30,
    interactivity: {
      detectsOn: "canvas",
      events: {
        resize: true
      }
    },
    emitters: {
      direction: "top",
      position: {
        x: 50,
        y: 130
      },
      size: {
        width: 100,
        height: 0
      },
      particles: makeStars(makeMove(magnitude, 0, 'top', random))
    },
    particles: makeStars(makeMove(magnitude, 0, 'none', true))
  }
  const getEdgeScrollingProps = useScrollOnEdges()
  return (
    <Particles {...getEdgeScrollingProps()} id="tsStars" height={1 * props.height} width={1 * props.width} options={data} />
  )
}