import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Navbar} from 'react-bootstrap'



const useContainerDimensions = myRef => {
//   const getDimensions = () => ({
//     width: myRef.current.offsetWidth,
//     height: myRef.current.offsetHeight
//   })

  const getDimensions = () => {
  	if (myRef !== undefined) {
  		return {
		    width: myRef.current.offsetWidth,
		    height: myRef.current.offsetHeight
		  }
  	} else {
  		return { width: -1, height: -1 }
  	}
  }



  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef !== undefined && myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions;
};

function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return keyPressed;
}


function getDimensions(ele) {
  // use another element here, pass it through, optionally 
  let element
  let hvar
  let wvar
  if (!ele) {
  	element = window
  	console.log('using element ', element)
  	hvar = 'innerHeight'
  	wvar = 'innerWidth'
  	
  } else {
  	element = ele
  	console.log('using element ', element)
  	hvar = 'clientHeight'
  	wvar = 'clientWidth'
  }
  // const { wvar: width, hvar: height } = element;
  const width = element[wvar]
  const height = element[hvar]
  console.log('width ', width, 'height ', height)
  return {
    width,
    height
  };
}

function useWindowDimensions(ele) {
  
  const [windowDimensions, setWindowDimensions] = useState(getDimensions(ele));

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getDimensions(ele));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function Center(Argument) {
  return (
  	<Col style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
  	  <Argument />
  	</Col>
  )
}

function CenterP(props) {
  return (
  	<Col style={{
  	    display: 'flex',
  	    alignItems: 'center',
  	    justifyContent: 'center',
        ...props.extraStyle,
  	}}>
  	{props.what}
  	</Col>
  )
}

function RowOf(props) {
  return (
    <Row >
      {props.what}
    </Row>
  )
}

function ColOf(props) {
  return (
    <Col>
      {props.what}
    </Col>
  )
}

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export { Center, CenterP, RowOf, ColOf, useContainerDimensions, useLocalStorage, useKeyPress, useWindowDimensions }