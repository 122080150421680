import React from 'react';
import { Container, Row, Col, Navbar, Nav, Dropdown, Form, DropdownButton} from 'react-bootstrap'

const controlStyles = { fontSize: 10 };

export default function LinkControls({
  layout,
  orientation,
  linkType,
  stepPercent,
  setLayout,
  setOrientation,
  setLinkType,
  setStepPercent,
  setDataName
}) {
  return (
    <div>
      <div style={controlStyles}>
        <label>layout:</label>&nbsp;
        <select
          onClick={e => e.stopPropagation()}
          onChange={e => setLayout(e.target.value)}
          value={layout}
        >
          <option value="cartesian">cartesian</option>
          <option value="polar">polar</option>
        </select>
        &nbsp;&nbsp;
        <label>orientation:</label>&nbsp;
        <select
          onClick={e => e.stopPropagation()}
          onChange={e => setOrientation(e.target.value)}
          value={orientation}
          disabled={layout === 'polar'}
        >
          <option value="vertical">vertical</option>
          <option value="horizontal">horizontal</option>
        </select>
        &nbsp;&nbsp;
        <label>link:</label>&nbsp;
        <select
          onClick={e => e.stopPropagation()}
          onChange={e => setLinkType(e.target.value)}
          value={linkType}
        >
          <option value="diagonal">diagonal</option>
          <option value="step">step</option>
          <option value="curve">curve</option>
          <option value="line">line</option>
        </select>
        {linkType === 'step' && layout !== 'polar' && (
          <>
            &nbsp;&nbsp;
            <label>step:</label>&nbsp;
            <input
              onClick={e => e.stopPropagation()}
              type="range"
              min={0}
              max={1}
              step={0.1}
              onChange={e => setStepPercent(Number(e.target.value))}
              value={stepPercent}
              disabled={linkType !== 'step' || layout === 'polar'}
            />
          </>
        )}
      </div>
      <div> 
      <Dropdown>
        <Dropdown.Toggle size={'sm'} id="dropdown-layout">
          {layout}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={e => setLayout('cartesian')}>cartesian</Dropdown.Item>
          <Dropdown.Item onClick={e => setLayout('polar')}>polar</Dropdown.Item>
        </Dropdown.Menu>
        <Dropdown.Toggle size={'sm'} id="dropdown-orientation">
          {orientation}
        </Dropdown.Toggle>
        <Dropdown.Menu disabled={layout === 'polar'}>
          <Dropdown.Item onClick={e => setOrientation('vertical')}>vertical</Dropdown.Item>
          <Dropdown.Item onClick={e => setOrientation('horizontal')}>horizontal</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Form>
        <Form.Group onChange={e => {console.log('hello'); setDataName(e.target.value)}} controlId="exampleForm.ControlSelect1">
          <Form.Label>Example select</Form.Label>
          <Form.Control as="select">
            {Object.keys(window.localStorage).map((item, key) => {
              return <option key={key}>{item}</option>
            })}
          </Form.Control>
        </Form.Group>
      </Form>
      </div>
    </div>
  );
}
